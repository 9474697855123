/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React, { useState, useRef } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Select({
  label,
  options,
  setFieldValue,
  value,
  name,
  setTouched,
  touched,
  width,
  type,
  required,
  placeholder,
}) {
  const [isOptionsContainerVisible, setIsOptionsContainerVisible] =
    useState(false);

  const uiElementsRef = useRef();

  useOnClickOutside(uiElementsRef, () => {
    setIsOptionsContainerVisible(false);
    if (isOptionsContainerVisible) {
      setTouched({ ...touched, [name]: true });
    }
  });

  return (
    <label className={classes.Select}>
      <span className={classes.label}>
        {label}
        {required && <span className={classes.required}>*</span>}
      </span>
      <div ref={uiElementsRef}>
        <div
          className={classNames(
            classes.selector,
            classes[type],
            isOptionsContainerVisible && classes.open
          )}
          style={{ width }}
          onClick={() => {
            setIsOptionsContainerVisible((prevState) => !prevState);
          }}
        >
          {value || placeholder || 'Please select'}
        </div>
        {isOptionsContainerVisible && (
          <div
            className={classNames(classes.options, classes[type])}
            style={{ width }}
          >
            {options?.map((option) => {
              let optionClass = classes[option.toLowerCase()];

              if (option === 'Creativity Agency') {
                optionClass = classes.creativityAgency;
              }

              return (
                <div
                  className={classNames(
                    classes.option,
                    optionClass,
                    value === option && classes.active
                  )}
                  key={option}
                  onClick={() => {
                    setFieldValue(name, option);
                    setIsOptionsContainerVisible(false);
                  }}
                >
                  {option}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </label>
  );
}
