import React, { useContext, useEffect } from 'react';

import { useQuery } from 'react-query';

import Sidebar from '../../components/Layout/Sidebar';
import Header from '../../components/Layout/Header';
import { UIContext } from '../../context';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';

export default function Layout({ children, noHeader, headerBackButton }) {
  const { isSidebarHidden, getWorkspaces } = useContext(UIContext);

  const { data: user } = useQuery('me', UserService.getMe);

  useEffect(() => {
    if (user) {
      getWorkspaces();
    }
  }, [getWorkspaces, user]);

  return (
    <div className={classes.Layout}>
      <div
        className={classes.leftColumn}
        style={{ width: isSidebarHidden ? 0 : '' }}
      >
        <Sidebar />
      </div>
      <div className={classes.rightColumn}>
        {!noHeader && (
          <header className={classes.header}>
            <Header headerBackButton={headerBackButton} />
          </header>
        )}
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  );
}
