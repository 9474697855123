import axios from 'axios';

import { API_URL } from '../constants/main';

const CommentsService = {
  async getTags(fileId) {
    const response = await axios.get(`${API_URL}/comments/tags/${fileId}`);
    return response.data;
  },
  async createVideoTag({
    videoTag,
    leftMarkPosition,
    rightMarkPosition,
    videoRef,
    fileId,
    activeWorkspace,
  }) {
    const response = await axios.post(`${API_URL}/comments`, {
      textContent: videoTag,
      startPosition: (leftMarkPosition / 100) * videoRef.current.duration,
      endPosition: (rightMarkPosition / 100) * videoRef.current.duration,
      fileId,
      workspaceId: activeWorkspace.id,
    });
    return response.data;
  },
  async createImageTag({
    tagText,
    imageRef,
    width,
    height,
    startingPoint,
    fileId,
  }) {
    const widthPercentage = (100 / imageRef.current.clientWidth) * width;
    const heightPercentage = (100 / imageRef.current.clientHeight) * height;

    const response = await axios.post(`${API_URL}/comments`, {
      textContent: tagText,
      top: startingPoint.topPercentage,
      left: startingPoint.leftPercentage,
      width: widthPercentage,
      height: heightPercentage,
      fileId,
    });

    return response.data;
  },
};

export default CommentsService;
