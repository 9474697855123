import React, { useState, useEffect, useRef } from 'react';

import { convertToHTML } from 'draft-convert';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './styles.scss';
import classes from './styles.module.scss';
import linkIcon from '../../../assets/images/editor/link.svg';
import boldIcon from '../../../assets/images/editor/bold.svg';
import underLineIcon from '../../../assets/images/editor/underline.svg';
import italicIcon from '../../../assets/images/editor/italic.svg';
import listIcon from '../../../assets/images/editor/list.svg';
import attachmentIcon from '../../../assets/images/editor/attachment.svg';

const toolbarOptions = {
  options: ['link', 'inline', 'list'],
  link: {
    options: ['link'],
    link: { icon: linkIcon, className: classes.toolbarIcon },
    showOpenOptionOnHover: false,
  },
  inline: {
    options: ['bold', 'underline', 'italic'],
    bold: { icon: boldIcon, className: classes.toolbarIcon },
    underline: { icon: underLineIcon, className: classes.toolbarIcon },
    italic: { icon: italicIcon, className: classes.toolbarIcon },
  },
  list: {
    options: ['unordered'],
    unordered: { icon: listIcon, className: classes.toolbarIcon },
  },
};

export default function EditorComponent({
  setFieldValue,
  name,
  touched,
  setTouched,
  setFiles,
  initialState,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [htmlContent, setHtmlContent] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fileInputRef = useRef();

  const changeEditorState = (state) => {
    setEditorState(state);
  };

  useEffect(() => {
    if (initialState) {
      const blocksFromHTML = convertFromHTML(initialState);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
      setIsPlaceholderVisible(false);
    }
  }, [initialState]);

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    setHtmlContent(html);
    setFieldValue(name, html);
  }, [editorState, name, setFieldValue]);

  useEffect(() => {
    if (!isFocused && (!htmlContent || htmlContent === '<p></p>')) {
      setIsPlaceholderVisible(true);
    }
    if (isFocused) {
      setIsPlaceholderVisible(false);
    }
  }, [isFocused, htmlContent]);

  return (
    <div className={classes.Editor}>
      <header>
        <span className={classes.limit}>Maximo 600 caracteres</span>
      </header>
      <div
        className={classes.placeholder}
        style={{ visibility: isPlaceholderVisible ? 'visible' : 'hidden' }}
      >
        Por favor ingresa una descripción de la Idea
      </div>
      <button
        type="button"
        className={classes.addAttachment}
        onClick={() => {
          fileInputRef.current.click();
        }}
      >
        <img src={attachmentIcon} alt="Attachment" />
      </button>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        multiple
        accept=".png, .jpg, .jpeg, .gif, .mp4, .webm"
        onChange={(event) => setFiles(event.target.files)}
      />
      <Editor
        toolbar={toolbarOptions}
        editorState={editorState}
        onEditorStateChange={changeEditorState}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setTouched({ ...touched, [name]: true }, true);
        }}
      />
    </div>
  );
}
