import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ConfirmTicketRemoval({ show, handleClose, onConfirm }) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Ticket and all associated files will be deleted permanently
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onConfirm();
            handleClose();
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
