/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useContext, useState, useEffect } from 'react';

import classes from './styles.module.scss';
import { UIContext } from '../../../../context';

export default function WorkspaceMenu({ workspaces }) {
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const workspaceMenuRef = useRef();

  const { activeWorkspace, setActiveWorkspace } = useContext(UIContext);

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredWorkspaces(workspaces);
    } else {
      setFilteredWorkspaces((prevValue) => {
        return prevValue.filter((workspace) =>
          workspace.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
  }, [searchTerm, workspaces]);

  return (
    <div className={classes.WorkspaceMenu} ref={workspaceMenuRef}>
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.74518 2.38894C8.78702 -0.0272701 12.213 -0.0272703 13.2548 2.38894L20.3856 18.9266C21.2395 20.9069 19.7874 23.1144 17.6308 23.1144H3.36921C1.21262 23.1144 -0.239502 20.9069 0.614393 18.9266L7.74518 2.38894Z"
          fill="white"
        />
      </svg>
      <header>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      </header>
      <ul>
        {filteredWorkspaces.map((workspace) => {
          return (
            <li
              key={workspace.id}
              onClick={() => {
                setActiveWorkspace(workspace);
                localStorage.setItem('workspace', JSON.stringify(workspace));
              }}
              className={
                activeWorkspace?.title === workspace.title && classes.active
              }
            >
              {workspace.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
