import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';

import ConfirmTicketRemoval from '../../../../modals/ConfirmTicketRemoval';
import { API_URL } from '../../../../constants/main';
import classes from './styles.module.scss';

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    className={classes.toggle}
    ref={ref}
    onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
      onClick(event);
    }}
  />
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
      </div>
    );
  }
);

export default function Menu({ cardRef, ticketId, getTickets }) {
  const [isConirmTicketRemovalModalVisible, setIsTicketRemovalModalVisible] =
    useState(false);

  const navigate = useNavigate();

  const hideMenu = () => {
    const toggle = cardRef.current?.querySelector(`.${classes.toggle}`);
    toggle?.click();
  };

  const deleteTicket = async () => {
    try {
      await axios.delete(`${API_URL}/tickets/${ticketId}`);
      hideMenu();
      getTickets();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />

        <Dropdown.Menu as={CustomMenu} className={classes.menu}>
          <Dropdown.Item
            eventKey="1"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/tickets/${ticketId}/edit`);
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={(event) => {
              event.stopPropagation();
              setIsTicketRemovalModalVisible(true);
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <ConfirmTicketRemoval
        show={isConirmTicketRemovalModalVisible}
        handleClose={() => {
          setIsTicketRemovalModalVisible(false);
          hideMenu();
        }}
        onConfirm={deleteTicket}
        hideMenu={hideMenu}
      />
    </>
  );
}
