/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect, useContext } from 'react';

import { useQuery } from 'react-query';
import classNames from 'classnames';
import classes from './styles.module.scss';
import playButton from '../../../assets/images/player/play.svg';
import volumeButton from '../../../assets/images/player/volume.svg';
import fullscreenButton from '../../../assets/images/player/fullscreen.svg';
import { STATIC_URL } from '../../../constants/main';
import useDraggable from '../../../hooks/useDraggable';
import useResizeObserver from '../../../hooks/useResizeObserver';
import { TicketContext } from '../../../context';
import CommentsService from '../../../services/CommentsService';
import VideoTag from '../Tag';

let timer;

export default function Player({
  videoUrl,
  videoTag,
  setVideoTag,
  videoRef,
  leftMarkPosition,
  setLeftMarkPosition,
  rightMarkPosition,
  setRightMarkPosition,
  fileId,
  getTagsTrigger,
  commentsMode,
}) {
  const [videoProgress, setVideoProgress] = useState(0);
  const [isCursorAnimationDisabled, setIsCursorAnimationDisabled] =
    useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [areControlsVisible, setAreControlsVisible] = useState(false);
  const [markPosition, setMarkPosition] = useState(0);
  const [isLeftMarkDragged, setIsLeftMarkDragged] = useState(false);

  const videoContainerRef = useRef();
  const handleRef = useRef();
  const progressBarRef = useRef();
  const timeBarRef = useRef();
  const markRef = useRef();
  const leftMarkRef = useRef();
  const rightMarkRef = useRef();
  const isMarkSet = useRef();

  const prevVideoState = useRef();

  const { activeVideoTag, setActiveVideoTag } = useContext(TicketContext);

  const playPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      prevVideoState.current = 'play';
    } else {
      videoRef.current.pause();
      prevVideoState.current = 'pause';
    }
  };

  const progressBarWidth = useResizeObserver(progressBarRef).width;

  const handleDrag = useDraggable(handleRef, setIsCursorAnimationDisabled);

  const markDrag = useDraggable(markRef, setIsCursorAnimationDisabled);

  const leftMarkDrag = useDraggable(leftMarkRef, setIsCursorAnimationDisabled);

  const { data: tags, refetch } = useQuery(
    ['tags', fileId],
    () => CommentsService.getTags(fileId),
    {
      enabled: false,
    }
  );

  const rightMarkDrag = useDraggable(
    rightMarkRef,
    setIsCursorAnimationDisabled
  );

  useEffect(() => {
    if (leftMarkDrag.isDragging) {
      setIsLeftMarkDragged(true);
    }
  }, [leftMarkDrag]);

  useEffect(() => {
    if (markDrag.isDragging) {
      setIsLeftMarkDragged(false);
    }
  }, [markDrag]);

  useEffect(() => {
    videoContainerRef.current.style.setProperty(
      '--selectionLength',
      `${
        ((100 - leftMarkPosition - (100 - rightMarkPosition)) *
          progressBarWidth) /
        100
      }px`
    );
  }, [leftMarkPosition, progressBarWidth, rightMarkPosition]);

  useEffect(() => {
    if (activeVideoTag) {
      videoRef.current.pause();
      // eslint-disable-next-line no-param-reassign
      videoRef.current.currentTime = activeVideoTag.startPosition;

      const tagDuration =
        activeVideoTag.startPosition - activeVideoTag.endPosition;
      const tagDurationInPixels =
        (tagDuration / videoRef.current.duration) * progressBarWidth;
      videoContainerRef.current.style.setProperty(
        '--tagLength',
        `${Math.abs(tagDurationInPixels)}px`
      );
    }
  }, [activeVideoTag, progressBarWidth, videoRef]);

  useEffect(() => {
    setVideoTag('');
    setActiveVideoTag(null);
  }, [videoUrl, setVideoTag, setActiveVideoTag]);

  useEffect(() => {
    refetch();
  }, [getTagsTrigger, refetch]);

  const getDraggableElementRelativePosition = (clientX, parentRef) => {
    const coords = parentRef.current?.getBoundingClientRect();
    const relativePositionX = Math.max(0, clientX - coords?.left);
    const percentage = Math.min(
      100,
      (100 / parentRef.current?.clientWidth) * relativePositionX
    );
    return percentage;
  };

  useEffect(() => {
    if (commentsMode === 'tags') {
      if (isMarkSet.current) {
        return;
      }
      setActiveVideoTag(null);
      setAreControlsVisible(true);
      videoRef.current.pause();
      setMarkPosition(
        (100 / videoRef.current.duration) * videoRef.current.currentTime
      );
      isMarkSet.current = true;
    } else {
      setAreControlsVisible(false);
      isMarkSet.current = false;
      setIsLeftMarkDragged(false);
    }
  }, [markPosition, commentsMode, videoRef, setActiveVideoTag]);

  useEffect(() => {
    if (activeVideoTag) {
      setVideoTag('');
    }
  }, [activeVideoTag, setVideoTag]);

  useEffect(() => {
    const percentage = getDraggableElementRelativePosition(
      handleDrag.xPosition,
      progressBarRef
    );
    setVideoProgress(percentage);
  }, [handleDrag.xPosition]);

  useEffect(() => {
    const percentage = getDraggableElementRelativePosition(
      markDrag.xPosition,
      timeBarRef
    );
    setVideoProgress(percentage);
    setMarkPosition(percentage);
  }, [markDrag.xPosition]);

  useEffect(() => {
    setLeftMarkPosition(markPosition);
    if (!isLeftMarkDragged) {
      setRightMarkPosition(markPosition);
    }
  }, [
    markPosition,
    isLeftMarkDragged,
    setLeftMarkPosition,
    setRightMarkPosition,
  ]);

  useEffect(() => {
    const relativePosition = getDraggableElementRelativePosition(
      leftMarkDrag.xPosition,
      timeBarRef
    );

    if (relativePosition > rightMarkPosition) {
      return;
    }

    setLeftMarkPosition(relativePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftMarkDrag.xPosition]);

  useEffect(() => {
    const relativePosition = getDraggableElementRelativePosition(
      rightMarkDrag.xPosition,
      timeBarRef
    );

    if (relativePosition < markPosition) {
      return;
    }

    setRightMarkPosition(relativePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightMarkDrag.xPosition]);

  useEffect(() => {
    setVideoProgress(leftMarkPosition);
    setMarkPosition(leftMarkPosition);
  }, [leftMarkPosition]);

  useEffect(() => {
    setVideoProgress(rightMarkPosition);
  }, [rightMarkPosition, leftMarkDrag.isDragging]);

  useEffect(() => {
    if (isCursorAnimationDisabled) {
      videoRef.current.pause();
    } else if (!isCursorAnimationDisabled) {
      if (prevVideoState.current === 'play' && !videoTag.trim()) {
        videoRef.current.play();
      }
    }
  }, [isCursorAnimationDisabled, videoRef, videoTag]);

  useEffect(() => {
    if (videoRef.current?.duration && isCursorAnimationDisabled) {
      videoRef.current.currentTime =
        videoRef.current?.duration * (videoProgress / 100);
    }
  }, [videoProgress, isCursorAnimationDisabled, videoRef]);

  const toggleFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      document.exitFullscreen();
    } else {
      videoContainerRef.current.requestFullscreen();
    }
  };

  const toggleMute = () => {
    if (videoRef.current.muted) {
      setIsMuted(false);
    } else {
      setIsMuted(true);
    }
  };

  const setVideoPosition = (event) => {
    const percentage = getDraggableElementRelativePosition(
      event.clientX,
      progressBarRef
    );
    videoRef.current.currentTime =
      videoRef.current?.duration * (percentage / 100);
  };

  useEffect(() => {
    let animationId;

    const animateProgressBar = () => {
      if (isCursorAnimationDisabled) {
        return;
      }

      setVideoProgress(
        (100 / videoRef.current?.duration) * videoRef.current?.currentTime
      );

      animationId = requestAnimationFrame(animateProgressBar);
    };

    animationId = requestAnimationFrame(animateProgressBar);

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [isCursorAnimationDisabled, videoRef]);

  const toggleControls = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      commentsMode === 'tags'
    ) {
      return;
    }

    setAreControlsVisible((prevState) => !prevState);
  };

  const showControlsFullscreen = () => {
    clearTimeout(timer);
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.mozFullScreenElement
    ) {
      return;
    }

    setAreControlsVisible(true);

    timer = setTimeout(() => {
      setAreControlsVisible(false);
    }, 2000);
  };

  return (
    <div className={classes.Player} ref={videoContainerRef}>
      <video
        src={`${STATIC_URL}${videoUrl}`}
        ref={videoRef}
        onClick={playPause}
        muted={isMuted}
        onMouseEnter={toggleControls}
        onMouseLeave={toggleControls}
        onMouseMove={showControlsFullscreen}
      />

      <div
        className={classes.controls}
        onMouseEnter={toggleControls}
        onMouseLeave={toggleControls}
        style={{ visibility: areControlsVisible ? 'visible' : 'hidden' }}
      >
        <div
          className={classNames(classes.playButton, classes.button)}
          onClick={playPause}
        >
          <img src={playButton} alt="Play" />
        </div>
        <div
          className={classNames(classes.volumeButton, classes.button)}
          onClick={toggleMute}
        >
          <img src={volumeButton} alt="Volume" />
        </div>
        <div
          className={classNames(classes.fullscreenButton, classes.button)}
          onClick={toggleFullscreen}
        >
          <img src={fullscreenButton} alt="Fullscreen" />
        </div>
        <div
          className={classes.progressBar}
          ref={progressBarRef}
          onClick={setVideoPosition}
        >
          <div className={classes.tags}>
            {commentsMode === 'tags' &&
              tags &&
              tags.map((tag) => {
                return (
                  <VideoTag
                    key={tag.id}
                    tag={tag}
                    left={
                      (100 / videoRef?.current?.duration) * tag.startPosition
                    }
                  />
                );
              })}
          </div>
          <div
            className={classes.progress}
            style={{
              width: `${videoProgress}%`,
              transition: isCursorAnimationDisabled ? '' : 'width 0.1s linear',
            }}
          />
          <div
            className={classes.handle}
            style={{
              left: `calc(${videoProgress}% - 6px)`,
              transition: isCursorAnimationDisabled ? '' : 'left 0.1s linear',
            }}
            ref={handleRef}
          />
        </div>

        <div
          className={classes.timeSelector}
          ref={timeBarRef}
          style={{
            visibility: commentsMode === 'tags' ? 'visible' : 'hidden',
          }}
        >
          <div
            ref={leftMarkRef}
            className={classes.markLeft}
            style={{ left: `calc(${leftMarkPosition}% - 8px)` }}
          />
          <div
            ref={markRef}
            className={classes.mark}
            style={{ left: `calc(${markPosition}% - 6px)` }}
          />
          <div
            ref={rightMarkRef}
            className={classes.markRight}
            style={{ left: `calc(${rightMarkPosition}% + 6px)` }}
          />
        </div>

        {activeVideoTag && (
          <div className={classes.tagContainer}>
            <div
              className={classNames(classes.tagLeftMark, classes.mark)}
              style={{
                left: `calc(${
                  (100 / videoRef.current?.duration) *
                  activeVideoTag.startPosition
                }% - 6px)`,
              }}
            />
            <div
              className={classNames(classes.tagRightMark, classes.mark)}
              style={{
                left: `calc(${
                  (100 / videoRef.current?.duration) *
                  activeVideoTag.endPosition
                }% - 6px)`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
