import React, { useEffect, useContext } from 'react';

import { useQuery } from 'react-query';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import classes from './styles.module.scss';
import { UIContext } from '../../../context';
import logo from '../../../assets/images/logo.svg';
import UserService from '../../../services/UserService';

const flowMenuItems = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    className: 'dashboard',
  },
  {
    title: 'Brief',
    link: '/brief',
    className: 'designReview',
  },
  {
    title: 'Production',
    link: '/production',
    className: 'production',
  },
  {
    title: 'Ready For Review',
    link: '/review',
    className: 'review',
  },
  {
    title: 'Edit Requests',
    link: '/editrequests',
    className: 'editrequests',
  },
  {
    title: 'Approved',
    link: '/approved',
    className: 'approved',
  },
  {
    title: 'Completed',
    link: '/completed',
    className: 'completed',
  },
];

const channelsMenuItems = [
  {
    title: 'Facebook',
    link: '/channels/facebook',
    className: 'facebook',
  },
  {
    title: 'Instagram',
    link: '/channels/instagram',
    className: 'instagram',
  },
  {
    title: 'Snapchat',
    link: '/channels/snapchat',
    className: 'snapchat',
  },
  {
    title: 'TikTok',
    link: '/channels/tiktok',
    className: 'tikTok',
  },
  {
    title: 'Youtube',
    link: '/channels/youtube',
    className: 'youTube',
  },
];

export default function Sidebar() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const { data: user } = useQuery('me', UserService.getMe);

  const {
    sidebarRef,
    isSidebarHidden,
    setIsSidebarHidden,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  } = useContext(UIContext);

  useEffect(() => {
    if (!isSidebarHidden && isTabletOrMobile) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }

    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, [isSidebarHidden, isTabletOrMobile]);

  return (
    <>
      {isTabletOrMobile && !isSidebarHidden && (
        <div
          className={classes.backdrop}
          onClick={() => setIsSidebarHidden(true)}
        />
      )}
      <div
        className={classNames(
          classes.filler,
          isSidebarCollapsed && classes.collapsed
        )}
        style={{ left: isSidebarHidden ? '-100%' : '' }}
      />
      <div
        style={{ left: isSidebarHidden ? '-211px' : 0 }}
        className={classNames(
          classes.Sidebar,
          isSidebarCollapsed && classes.collapsed
        )}
        ref={sidebarRef}
        onClick={() => {
          if (isTabletOrMobile) {
            setIsSidebarHidden((prevState) => !prevState);
          } else {
            setIsSidebarCollapsed((prevState) => !prevState);
          }
        }}
      >
        <Link
          to="/dashboard"
          onClick={(event) => {
            if (!isTabletOrMobile) {
              event.stopPropagation();
            }
          }}
        >
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
        <div className={classes.userBlock}>
          <Link
            to="/profile"
            onClick={(event) => {
              if (!isTabletOrMobile) {
                event.stopPropagation();
              }
            }}
          >
            <div className={classes.userPic}>
              <span>{user?.name?.[0]}</span>
            </div>
          </Link>
          <div>
            <p className={classes.username}>{user?.name}</p>
            <p className={classes.companyName}>{user?.company}</p>
          </div>
        </div>
        <nav className={classes.flow}>
          <h2>Flow</h2>
          <ul>
            {flowMenuItems.map((item) => {
              if (user && !user.companyType.includes('Agency')) {
                if (
                  item.title === 'Approved' ||
                  item.title === 'Edit Requests'
                ) {
                  return false;
                }
              }
              return (
                <li key={item.className}>
                  <NavLink
                    to={item.link}
                    onClick={(event) => {
                      if (!isTabletOrMobile) {
                        event.stopPropagation();
                      }
                      if (item.disabled) {
                        event.stopPropagation();
                        event.preventDefault();
                      }
                    }}
                  >
                    {({ isActive }) => (
                      <span
                        className={classNames(
                          isActive && classes.activeLink,
                          classes[item.className]
                        )}
                      >
                        {item.title}
                      </span>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <nav className={classes.channels}>
          <h2>{isSidebarCollapsed ? 'Chan' : 'Channels'}</h2>
          <ul>
            {channelsMenuItems.map((item) => {
              return (
                <li key={item.className}>
                  <NavLink
                    to={item.link}
                    onClick={(event) => {
                      if (!isTabletOrMobile) {
                        event.stopPropagation();
                      }
                      if (item.disabled) {
                        event.stopPropagation();
                        event.preventDefault();
                      }
                    }}
                  >
                    {({ isActive }) => (
                      <span
                        className={classNames(
                          isActive && classes.activeLink,
                          classes[item.className]
                        )}
                      >
                        {item.title}
                      </span>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </>
  );
}
