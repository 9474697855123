import React, { useRef, useState, useMemo, useEffect } from 'react';

import classNames from 'classnames';

import Card from '../../../components/Dashboard/Card';

export default function CardsRow({ cards = [], classes, video, getTickets }) {
  const [cardsCount, setCardsCount] = useState(0);
  const [containerWidth, setContainerWidth] = useState('');
  const [cardsPage, setCardsPage] = useState(0);
  const [cardsScrollPosition, setCardsScrollPosition] = useState(0);

  const cardsContainerRef = useRef();

  const myObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          // setCardsContainerWidth(entry.contentRect.width);
          setCardsCount(Math.floor(entry.contentRect.width / (260 + 60)));
        });
      }),
    []
  );

  useEffect(() => {
    if (cardsContainerRef.current) {
      myObserver.observe(cardsContainerRef.current);
    }
    return () => {
      myObserver.disconnect();
    };
  }, [myObserver]);

  useEffect(() => {
    setContainerWidth(cardsCount * (260 + 52));
  }, [cardsCount]);

  useEffect(() => {
    setCardsPage(0);
    setCardsScrollPosition(0);
  }, [cards]);

  return (
    <div className={video ? classes.videos : classes.statics}>
      <h2>{video ? 'Videos' : 'Statics'}</h2>
      <div className={classes.cards} ref={cardsContainerRef}>
        {cardsPage !== 0 && (
          <button
            type="button"
            className={classNames(classes.scrollLeft, classes.scrollButton)}
            onClick={() => {
              setCardsPage((prevPage) => {
                setCardsScrollPosition(
                  -(cardsPage - 1) * cardsCount * (260 + 52)
                );
                return prevPage - 1;
              });
            }}
          >
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.73999 8.53L5.25999 5L1.73999 1.47"
                stroke="#5F6073"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        {cards?.length - (cardsPage + 1) * cardsCount > 0 && (
          <button
            type="button"
            className={classNames(classes.scrollRight, classes.scrollButton)}
            onClick={() => {
              setCardsPage((prevPage) => {
                setCardsScrollPosition(
                  -(cardsPage + 1) * cardsCount * (260 + 52)
                );
                return prevPage + 1;
              });
            }}
          >
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.73999 8.53L5.25999 5L1.73999 1.47"
                stroke="#5F6073"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <div className={classes.container} style={{ width: containerWidth }}>
          <div
            className={classes.scrollable}
            style={{ left: cardsScrollPosition }}
          >
            {cards.map((card) => {
              return (
                <Card key={card.id} cardData={card} getTickets={getTickets} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
