/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import Header from '../../components/Layout/Header';
import Notifications from '../../components/Dashboard/Notifications';
import ConnectSnapchatModal from '../../modals/ConnectSnapchatModal';
import { getAuthData } from '../../helpers/authStorage';
import { API_URL } from '../../constants/main';
import classes from './styles.module.scss';
import UserService from '../../services/UserService';
import { logout } from '../../helpers/globalAuth';

export default function ProfilePage() {
  const [isRightSidebarCollapsed, setIsRightsidebarCollapsed] = useState(false);
  const [hasActiveSlackIntegration, setHasActiveSlackIntegration] =
    useState(false);
  const [isConnectSnapchatModalVisible, setIsConnectSnapchatModalVisible] =
    useState(false);
  const [hasActiveSnapchatIntegration, setHasActiveSnapchatIntegration] =
    useState(false);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigate = useNavigate();

  const { data: user } = useQuery('me', UserService.getMe);

  const installSlackApp = async () => {
    if (hasActiveSlackIntegration) {
      return;
    }
    try {
      const authData = getAuthData();
      window.open(
        `${API_URL}/slack-integration/install?token=${authData.accessToken}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function checkSlackIntegration() {
      try {
        const response = await axios.get(`${API_URL}/slack-integration/verify`);
        if (response.data.isActive) {
          setHasActiveSlackIntegration(true);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function checkSnapchatIntegration() {
      try {
        const response = await axios.get(
          `${API_URL}/snapchat-integration/verify`
        );
        if (response.data.isActive) {
          setHasActiveSnapchatIntegration(true);
        }
      } catch (error) {
        console.log(error);
      }
    }

    checkSlackIntegration();
    checkSnapchatIntegration();
  }, []);

  return (
    <div className={classes.ProfilePage}>
      <div className={classes.content}>
        <header>
          <Header />
        </header>
        <div className={classes.card}>
          <header className={classes.header}>
            <p onClick={() => logout(() => navigate('/signin'))}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.90002 8.06023C9.21002 4.46023 11.06 2.99023 15.11 2.99023H15.24C19.71 2.99023 21.5 4.78023 21.5 9.25023V15.7702C21.5 20.2402 19.71 22.0302 15.24 22.0302H15.11C11.09 22.0302 9.24002 20.5802 8.91002 17.0402"
                  stroke="#1B1C37"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 12.5H3.62"
                  stroke="#1B1C37"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.85 9.1499L2.5 12.4999L5.85 15.8499"
                  stroke="#1B1C37"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Logout
            </p>
          </header>
          <div className={classes.container}>
            <div className={classes.avatar}>{user?.name[0]}</div>
            <div className={classes.userInfo}>
              <h1>{user?.name}</h1>
              <span className={classes.email}>{user?.email}</span>
              <h2>Company</h2>
              <span className={classes.companyName}>{user?.company}</span>
            </div>
            <div className={classes.buttons}>
              <button
                type="button"
                className={classes.slackButton}
                onClick={() => {
                  if (!hasActiveSnapchatIntegration) {
                    setIsConnectSnapchatModalVisible(true);
                  }
                }}
              >
                Snap
                <div
                  className={classNames(
                    classes.connectionStatus,
                    hasActiveSnapchatIntegration && classes.active
                  )}
                />
              </button>
              <button
                type="button"
                className={classes.slackButton}
                onClick={installSlackApp}
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0954 16.9813C20.0954 18.4063 21.2471 19.5579 22.672 19.5579C24.0969 19.5579 25.2485 18.4063 25.2485 16.9813C25.2485 15.5564 24.0969 14.4048 22.672 14.4048H20.0954V16.9813Z"
                    fill="#E01E5A"
                  />
                  <path
                    d="M18.8071 16.9813C18.8071 15.5564 17.6555 14.4048 16.2306 14.4048C14.8057 14.4048 13.654 15.5564 13.654 16.9813V23.4227C13.654 24.8477 14.8057 25.9993 16.2306 25.9993C17.6555 25.9993 18.8071 24.8477 18.8071 23.4227V16.9813Z"
                    fill="#E01E5A"
                  />
                  <path
                    d="M16.2304 5.15312C17.6554 5.15312 18.807 4.00147 18.807 2.57656C18.807 1.15164 17.6554 0 16.2304 0C14.8055 0 13.6539 1.15164 13.6539 2.57656V5.15312H16.2304Z"
                    fill="#36C5F0"
                  />
                  <path
                    d="M16.2305 6.46045C14.8056 6.46045 13.6539 7.61209 13.6539 9.03701C13.6539 10.4619 14.8056 11.6136 16.2305 11.6136H22.6914C24.1163 11.6136 25.2679 10.4619 25.2679 9.03701C25.2679 7.61209 24.1163 6.46045 22.6914 6.46045H16.2305Z"
                    fill="#36C5F0"
                  />
                  <path
                    d="M5.90454 9.03701C5.90454 7.61209 4.7529 6.46045 3.32798 6.46045C1.90307 6.46045 0.751423 7.61209 0.751423 9.03701C0.751423 10.4619 1.90307 11.6136 3.32798 11.6136H5.90454V9.03701Z"
                    fill="#2EB67D"
                  />
                  <path
                    d="M7.19295 9.03747C7.19295 10.4624 8.34459 11.614 9.76951 11.614C11.1944 11.614 12.3461 10.4624 12.3461 9.03747V2.57656C12.3461 1.15164 11.1944 0 9.76951 0C8.34459 0 7.19295 1.15164 7.19295 2.57656V9.03747Z"
                    fill="#2EB67D"
                  />
                  <path
                    d="M9.76951 20.8462C8.34459 20.8462 7.19295 21.9978 7.19295 23.4227C7.19295 24.8477 8.34459 25.9993 9.76951 25.9993C11.1944 25.9993 12.3461 24.8477 12.3461 23.4227V20.8462H9.76951Z"
                    fill="#ECB22E"
                  />
                  <path
                    d="M9.76951 19.5579C11.1944 19.5579 12.3461 18.4063 12.3461 16.9813C12.3461 15.5564 11.1944 14.4048 9.76951 14.4048H3.3086C1.88368 14.4048 0.73204 15.5564 0.73204 16.9813C0.73204 18.4063 1.88368 19.5579 3.3086 19.5579H9.76951Z"
                    fill="#ECB22E"
                  />
                </svg>
                <div
                  className={classNames(
                    classes.connectionStatus,
                    hasActiveSlackIntegration && classes.active
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {!isTabletOrMobile && (
        <div className={classes.rightSidebarContainer}>
          <Notifications
            isCollapsed={isRightSidebarCollapsed}
            setIsCollapsed={setIsRightsidebarCollapsed}
          />
        </div>
      )}
      <ConnectSnapchatModal
        show={isConnectSnapchatModalVisible}
        handleClose={() => setIsConnectSnapchatModalVisible(false)}
      />
    </div>
  );
}
