import React, { useContext } from 'react';

import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { UIContext } from '../../context';
import { API_URL } from '../../constants/main';
import TextInput from '../../components/AuthForm/TextInput';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
});

export default function ForgotPassword() {
  const initialValues = {
    email: '',
  };

  const { notifySuccess, notifyError } = useContext(UIContext);

  const requestPasswordReset = async (values) => {
    try {
      await axios.post(`${API_URL}/forgot-password`, { email: values.email });
      notifySuccess('A reset password link has been sent to your email');
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: requestPasswordReset,
  });

  return (
    <div className={classes.ForgotPassword}>
      <div className={classes.container}>
        <h1>Reset Password</h1>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className={classes.inputContainer}>
            <TextInput
              required
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <div className={classes.error}>{formik.errors.email}</div>
            )}
          </div>
          <button className={classes.submitButton} type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
