/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useRef } from 'react';

import striptags from 'striptags';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';

import classNames from 'classnames';
import Menu from './Menu';
import classes from './styles.module.scss';
import youtubeIcon from '../../../assets/images/ticket/youtube.svg';
import metaIcon from '../../../assets/images/ticket/meta.svg';
import tikTokIcon from '../../../assets/images/ticket/tikTok.svg';
import snapchatIcon from '../../../assets/images/ticket/snapchat.svg';
import { STATIC_URL } from '../../../constants/main';

export default function Card({ cardData, getTickets }) {
  const navigate = useNavigate();

  const timeAgo = new TimeAgo('en-US');

  const cardRef = useRef();

  const images = cardData.Files.filter((file) => file.type === 'image');

  let statusText = cardData.status;

  if (statusText === 'editrequests') {
    statusText = 'Edit Requests';
  } else if (statusText === 'readyforreview') {
    statusText = 'Ready For Review';
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <article
      ref={cardRef}
      className={classes.Card}
      onClick={() => navigate(`/tickets/${cardData.id}`)}
    >
      <header>
        {images.length ? (
          <img alt={images[0].name} src={STATIC_URL + images[0].path} />
        ) : null}
        <p className={classes.statusTitle}>{statusText}</p>
        <div className={classNames(classes.status, classes[cardData.status])} />
      </header>
      <section className={classes.content}>
        <div className={classes.header}>
          <h1>{cardData.name}</h1>
          <div onClick={(event) => event.stopPropagation()}>
            <Menu
              cardRef={cardRef}
              ticketId={cardData.id}
              getTickets={getTickets}
            />
          </div>
        </div>
        <span className={classes.time}>
          {timeAgo.format(new Date(cardData.createdAt))}
        </span>
        <p className={classes.description}>{striptags(cardData.description)}</p>
        {cardData.Files.some((file) => file.type === 'image') && (
          <span className={classes.cardType}>Photo</span>
        )}
        <span
          className={classes.cardType}
          style={{
            visibility: cardData.Files.some((file) => file.type === 'video')
              ? 'visible'
              : 'hidden',
          }}
        >
          Video
        </span>
      </section>
      <footer>
        <div className={classes.platforms}>
          {(cardData.channels.includes('Facebook') ||
            cardData.channels.includes('Instagram')) && (
            <img src={metaIcon} alt="Meta" />
          )}
          {cardData.channels.includes('Snapchat') && (
            <img src={snapchatIcon} alt="Snapchat" />
          )}
          {cardData.channels.includes('TikTok') && (
            <img src={tikTokIcon} alt="TikTok" />
          )}
          {cardData.channels.includes('YouTube') && (
            <img src={youtubeIcon} alt="YouTube" />
          )}
        </div>
        <div className={classes.comments}>
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8068 14.1451H13.2239C12.6102 14.1451 12.0273 14.3775 11.5977 14.7975L10.2861 16.0651C9.6878 16.6426 8.71365 16.6426 8.11536 16.0651L6.8037 14.7975C6.37416 14.3775 5.78354 14.1451 5.17757 14.1451H4.60228C3.32899 14.1451 2.30115 13.1476 2.30115 11.9176V3.73505C2.30115 2.50505 3.32899 1.50757 4.60228 1.50757H13.8068C15.0801 1.50757 16.108 2.50505 16.108 3.73505V11.9176C16.108 13.1401 15.0801 14.1451 13.8068 14.1451Z"
              stroke="#5F6073"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {cardData.Comments.length}
        </div>
      </footer>
    </article>
  );
}
