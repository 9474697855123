import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';

import EmailMultiInput from '../../components/AuthForm/EmailMultiInput';
import TextInput from '../../components/AuthForm/TextInput';
import { API_URL } from '../../constants/main';
import { UIContext } from '../../context';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  company: yup.string().trim().required('This field is required'),
  members: yup.string().trim().required('This field is required'),
});

export default function AddWorkspace() {
  const navigate = useNavigate();

  const initialValues = {
    company: '',
    members: '',
  };

  const { getWorkspaces } = useContext(UIContext);

  const createWorkspace = async (values) => {
    try {
      await axios.post(`${API_URL}/workspaces`, {
        title: values.company,
        emails: values.members.split(','),
      });
      await getWorkspaces();
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: createWorkspace,
  });

  return (
    <div className={classes.AddWorkspace}>
      <div className={classes.container}>
        <h1>Add Workspace</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.inputContainer}>
            <TextInput
              label="Company"
              name="company"
              placeholder="Enter your company"
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              type="name"
            />
            {formik.errors.company && formik.touched.company && (
              <div className={classes.error}>{formik.errors.company}</div>
            )}
          </div>
          <div className={classNames(classes.inputContainer, classes.lastOne)}>
            <EmailMultiInput
              label="Members"
              name="members"
              placeholder="Enter email"
              value={formik.values.members}
              setFieldValue={formik.setFieldValue}
              onBlur={formik.handleBlur}
              required
            />
            {formik.errors.members && formik.touched.members && (
              <div className={classes.error}>{formik.errors.members}</div>
            )}
          </div>
          <button className={classes.submitButton} type="submit">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}
