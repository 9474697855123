/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback, useState, useRef, useEffect } from 'react';

import classNames from 'classnames';
import useDraggable from '../../../hooks/useDraggable';
import { STATIC_URL } from '../../../constants/main';
import classes from './styles.module.scss';

let timer;

export default function MediaSlider({
  ticket,
  currentFileIndex,
  setCurrentFileIndex,
}) {
  const [isSwitching, setIsSwitching] = useState(false);
  const [isSwitchingBackground, setIsSwitchingBackground] = useState(false);
  const [nextFileIndex, setNextFileIndex] = useState(0);
  const [scrollFileIndex, setScrollFileIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevMediaIndex, setPrevMediaIndex] = useState(1);
  const [nextMediaIndex, setNextMediaIndex] = useState(1);

  const thumbRef = useRef();
  const trackRef = useRef();
  const offset = useRef();

  const { isDragging, yPosition, yClickPosition } = useDraggable(thumbRef);

  const disableScroll = useCallback((event) => {
    event.preventDefault();
  }, []);

  const startBackgroundAnimation = () => {
    setIsSwitchingBackground(true);
    setTimeout(() => {
      setIsSwitchingBackground(false);
    }, 120);
  };

  const switchSlider = (page) => {
    setIsSwitching(true);
    setNextFileIndex(page);
    setTimeout(() => {
      setCurrentFileIndex(page);
    }, 300);
    setTimeout(() => {
      setIsSwitching(false);
    }, 320);
  };

  const handleScroll = (event) => {
    startBackgroundAnimation();

    if (event.deltaY > 0) {
      if (scrollFileIndex < ticket.Files?.length - 1) {
        setScrollFileIndex((prevIndex) => prevIndex + 1);
        clearTimeout(timer);
        timer = setTimeout(() => {
          switchSlider(scrollFileIndex + 1);
        }, 300);
      }
    } else if (scrollFileIndex > 0) {
      setScrollFileIndex((prevIndex) => prevIndex - 1);
      clearTimeout(timer);
      timer = setTimeout(() => {
        switchSlider(scrollFileIndex - 1);
      }, 300);
    }
  };

  const handlClickOnTrack = (event) => {
    startBackgroundAnimation();

    const coords = trackRef.current?.getBoundingClientRect();
    const relativeClickYPosition = event.clientY - coords.top;
    const fileToScrollBarHeight =
      trackRef.current?.offsetHeight / ticket?.Files?.length;
    let fileIndex = Math.round(relativeClickYPosition / fileToScrollBarHeight);

    if (fileIndex > ticket?.Files?.length - 1) {
      fileIndex = ticket?.Files?.length - 1;
    }
    setIsSwitching(true);
    setScrollFileIndex(fileIndex);
    setNextFileIndex(fileIndex);
    setTimeout(() => {
      setCurrentFileIndex(fileIndex);
    }, 300);
    setTimeout(() => {
      setIsSwitching(false);
    }, 320);
  };

  const handleClickOnMedia = () => {
    startBackgroundAnimation();

    if (currentFileIndex === ticket?.Files?.length - 1) {
      switchSlider(0);
      setScrollFileIndex(0);
    } else {
      switchSlider(currentFileIndex + 1);
      setScrollFileIndex(currentFileIndex + 1);
    }
  };

  useEffect(() => {
    startBackgroundAnimation();

    const coords = trackRef.current?.getBoundingClientRect();

    const relativeYPosition = yPosition - coords?.top - yClickPosition;

    if (
      relativeYPosition >= 0 &&
      relativeYPosition <=
        trackRef.current.offsetHeight - thumbRef.current.offsetHeight
    ) {
      setScrollPosition(relativeYPosition);
    }
  }, [yClickPosition, yPosition]);

  useEffect(() => {
    if (!isDragging) {
      setScrollFileIndex(currentFileIndex);
      setNextFileIndex(currentFileIndex);
      offset.current = scrollPosition;
    }
  }, [isDragging]);

  useEffect(() => {
    if (!isDragging) {
      return;
    }
    const fileToScrollBarHeight =
      trackRef.current?.offsetHeight / ticket?.Files?.length;
    setCurrentFileIndex(Math.round(scrollPosition / fileToScrollBarHeight));
  }, [isDragging, scrollPosition, ticket?.Files?.length]);

  useEffect(() => {
    setScrollPosition(
      ((scrollFileIndex * 100) / ticket?.Files?.length / 100) *
        trackRef.current?.offsetHeight
    );
  }, [isDragging, scrollFileIndex, ticket?.Files?.length]);

  useEffect(() => {
    if (ticket?.Files?.length > 1) {
      setNextFileIndex(1);
    }
  }, [ticket?.Files?.length]);

  useEffect(() => {
    if (scrollFileIndex !== 0) {
      setPrevMediaIndex(scrollFileIndex - 1);
    }

    if (scrollFileIndex !== ticket?.Files?.length - 1) {
      setNextMediaIndex(scrollFileIndex + 1);
    }
  }, [scrollFileIndex, ticket?.Files?.length]);

  return (
    <div
      className={classes.MediaSlider}
      onWheel={handleScroll}
      onMouseEnter={() => {
        window.addEventListener('wheel', disableScroll, {
          passive: false,
        });
      }}
      onMouseLeave={() => {
        window.removeEventListener('wheel', disableScroll, {
          passive: false,
        });
      }}
    >
      {ticket?.Files?.length ? (
        <>
          <div
            className={classNames(
              classes.prevMedia,
              isSwitchingBackground && classes.isSwitching
            )}
          >
            {currentFileIndex > 0 && (
              <>
                {ticket?.Files?.[prevMediaIndex]?.type === 'image' ? (
                  <img
                    src={`${STATIC_URL}${ticket?.Files?.[prevMediaIndex]?.path}`}
                    alt={ticket.Files?.[prevMediaIndex]?.name}
                  />
                ) : (
                  <video
                    src={`${STATIC_URL}${ticket?.Files?.[prevMediaIndex]?.path}`}
                  />
                )}
              </>
            )}
          </div>
          <div className={classes.mediaContent} onClick={handleClickOnMedia}>
            {ticket?.Files?.[currentFileIndex]?.type === 'image' ? (
              <>
                <img
                  className={isSwitching && classes.fadeOut}
                  src={`${STATIC_URL}${ticket?.Files?.[currentFileIndex]?.path}`}
                  alt={ticket.Files?.[currentFileIndex]?.name}
                />
                {!isDragging && (
                  <>
                    {ticket?.Files?.[nextFileIndex]?.type === 'image' ? (
                      <img
                        src={`${STATIC_URL}${ticket?.Files?.[nextFileIndex]?.path}`}
                        alt={ticket.Files?.[nextFileIndex]?.name}
                        className={classes.nextImage}
                      />
                    ) : (
                      <video
                        src={`${STATIC_URL}${ticket?.Files?.[nextFileIndex]?.path}`}
                        className={classes.nextImage}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <video
                  className={isSwitching && classes.fadeOut}
                  src={`${STATIC_URL}${ticket?.Files?.[currentFileIndex]?.path}`}
                />
                {!isDragging && (
                  <>
                    {ticket?.Files?.[nextFileIndex]?.type === 'image' ? (
                      <img
                        src={`${STATIC_URL}${ticket?.Files?.[nextFileIndex]?.path}`}
                        alt={ticket.Files?.[nextFileIndex]?.name}
                        className={classes.nextImage}
                      />
                    ) : (
                      <video
                        src={`${STATIC_URL}${ticket?.Files?.[nextFileIndex]?.path}`}
                        className={classes.nextImage}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div
            className={classNames(
              classes.nextMedia,
              isSwitchingBackground && classes.isSwitching
            )}
          >
            {currentFileIndex < ticket?.Files?.length - 1 && (
              <>
                {ticket?.Files?.[nextMediaIndex]?.type === 'image' ? (
                  <img
                    src={`${STATIC_URL}${ticket?.Files?.[nextMediaIndex]?.path}`}
                    alt={ticket.Files?.[nextMediaIndex]?.name}
                  />
                ) : (
                  <video
                    src={`${STATIC_URL}${ticket?.Files?.[nextMediaIndex]?.path}`}
                  />
                )}
              </>
            )}
          </div>
          <div
            ref={trackRef}
            className={classes.scrollbar}
            onClick={handlClickOnTrack}
          >
            <div
              className={classNames(
                classes.thumb,
                isDragging && classes.isDragging
              )}
              ref={thumbRef}
              style={{
                height: `calc(100% / ${ticket?.Files?.length})`,
                top: scrollPosition,
              }}
            />
          </div>
        </>
      ) : (
        <div>No content</div>
      )}
    </div>
  );
}
