/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function Select({
  label,
  options,
  setFieldValue,
  value,
  name,
  setTouched,
  touched,
  width,
}) {
  const [isOptionsContainerVisible, setIsOptionsContainerVisible] =
    useState(false);

  const selectRef = useRef();

  useOnClickOutside(selectRef, () => {
    setIsOptionsContainerVisible(false);
    if (isOptionsContainerVisible) {
      setTouched({ ...touched, [name]: true });
    }
  });

  return (
    <label className={classes.Select} ref={selectRef}>
      <span className={classes.label}>{label}</span>
      <div
        className={classes.selector}
        style={{ width }}
        onClick={() => {
          setIsOptionsContainerVisible(true);
        }}
      >
        {value || 'Please select'}
      </div>
      {isOptionsContainerVisible && (
        <div className={classes.options} style={{ width }}>
          {options?.map((option) => (
            <div
              className={classes.option}
              key={option}
              onClick={() => {
                setFieldValue(name, option);
                setIsOptionsContainerVisible(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </label>
  );
}
