/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect } from 'react';

import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import { login } from '../../helpers/globalAuth';
import TextInput from '../../components/AuthForm/TextInput';
import classes from './styles.module.scss';
import { UIContext } from '../../context';
import { API_URL } from '../../constants/main';

const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'The password should have at minimum length of 8'),
});

export default function SignIn() {
  const initialValues = {
    email: '',
    password: '',
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { notifyError, notifySuccess } = useContext(UIContext);

  const onSubmit = async (values) => {
    try {
      await login(values.email, values.password);
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
      notifyError('Wrong email or password');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    const resetPassword = async () => {
      try {
        await axios.post(`${API_URL}/reset-password`, {
          token: searchParams.get('resetPasswordToken'),
        });
        notifySuccess('Your new password has been sent to your email');
      } catch (error) {
        console.log(error);
        notifyError(error?.response?.data?.message);
      }
    };

    if (searchParams.has('resetPasswordToken')) {
      resetPassword();
    }
  }, [notifyError, notifySuccess, searchParams]);

  return (
    <div className={classes.SignIn}>
      <div className={classes.container}>
        <header className={classes.header}>
          <h1>Log In to your account</h1>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.inputContainer}>
            <TextInput
              label="Email"
              name="email"
              placeholder="Write your Email address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              type="email"
            />
            {formik.errors.email && formik.touched.email && (
              <div className={classes.error}>{formik.errors.email}</div>
            )}
          </div>
          <div className={classNames(classes.inputContainer, classes.lastOne)}>
            <TextInput
              type="password"
              label="Password"
              name="password"
              placeholder="Enter your password"
              value={formik.values.company}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.errors.password && formik.touched.password && (
              <div className={classes.error}>{formik.errors.password}</div>
            )}
            <Link className={classes.forgotPassword} to="/forgot-password">
              Forgot password?
            </Link>
          </div>
          <button className={classes.submitButton} type="submit">
            Submit
          </button>
          <span className={classes.noAccount}>
            Don’t have an account? <Link to="/signup">Sign Up</Link>
          </span>
        </form>
      </div>
    </div>
  );
}
