import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function TextInput({
  label,
  placeholder,
  maxLength = 200,
  onChange,
  name,
  onBlur,
  value,
  type,
  width,
  required,
}) {
  let inputType = type;
  if (type === 'name' || type === 'company') {
    inputType = 'text';
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classes.TextInput} style={{ width }}>
      <span className={classes.label}>
        {label}
        {required && <span className={classes.required}>*</span>}
      </span>
      <div className={classNames(classes.inputContainer, classes[type])}>
        <input
          style={{ width }}
          type={inputType}
          name={name}
          onBlur={onBlur}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </label>
  );
}
