import React, { useContext } from 'react';

import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import TextInput from '../../components/AuthForm/TextInput';

import { getAuthData } from '../../helpers/authStorage';
import { API_URL, DOMAIN } from '../../constants/main';
import { UIContext } from '../../context';
import classes from './styles.module.scss';

const validationSchema = yup.object({
  snapClientId: yup.string().trim().required('This field is required'),
  snapClientSecret: yup.string().trim().required('This field is required'),
});

export default function ConnectSnapchatModal({ show, handleClose }) {
  const initialValuesFirstStep = {
    snapClientId: '',
    snapClientSecret: '',
  };

  const { notifyError } = useContext(UIContext);

  const connectSnapchat = async (values) => {
    try {
      const response = await axios.post(
        `${API_URL}/snapchat-integration`,
        values
      );

      const authData = getAuthData();
      document.location.href = `https://accounts.snapchat.com/login/oauth2/authorize?client_id=${response.data.snapClientId}&redirect_uri=${DOMAIN}/api/snapchat-integration/oauth&response_type=code&scope=snapchat-marketing-api&state=${authData.accessToken}`;
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValuesFirstStep,
    validationSchema,
    onSubmit: connectSnapchat,
  });

  const closeModal = () => {
    setTimeout(() => {
      formik.handleReset();
    }, 300);
    handleClose();
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ConnectSnapchatModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <div className={classes.content}>
        <header>
          <h1>Connect Snapchat</h1>
          <button type="button" onClick={closeModal}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.17004 14.83L14.83 9.17"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.83 14.83L9.17004 9.17"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </header>
        <form>
          <div className={classes.inputContainer}>
            <TextInput
              label="Snap Client ID"
              name="snapClientId"
              placeholder="Please enter the Snap Client ID"
              value={formik.values.snapClientId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              type="none"
            />
            {formik.errors.snapClientId && formik.touched.snapClientId && (
              <div className={classes.error}>{formik.errors.snapClientId}</div>
            )}
          </div>
          <div className={classes.inputContainer}>
            <TextInput
              label="Snap Client Secret"
              name="snapClientSecret"
              placeholder="Please enter the Snap Client Secret"
              value={formik.values.snapClientSecret}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              type="none"
            />
            {formik.errors.snapClientSecret &&
              formik.touched.snapClientSecret && (
                <div className={classes.error}>
                  {formik.errors.snapClientSecret}
                </div>
              )}
          </div>
          <button
            type="button"
            onClick={formik.handleSubmit}
            className={classes.button}
          >
            Continue
          </button>
        </form>
      </div>
    </Modal>
  );
}
