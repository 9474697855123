import React, { useState, useContext } from 'react';

import striptags from 'striptags';

import { TicketContext } from '../../../context';
import classes from './styles.module.scss';

export default function VideoTag({ tag, left, top }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { setActiveImageTag, setActiveVideoTag } = useContext(TicketContext);

  return (
    <div
      className={classes.VideoTag}
      onClick={(event) => {
        event.stopPropagation();
        if (!top) {
          setActiveVideoTag(tag);
        }
      }}
      onMouseEnter={() => {
        setIsTooltipVisible(true);
        if (top) {
          setActiveImageTag(tag);
        }
      }}
      onMouseLeave={() => {
        setIsTooltipVisible(false);
        if (top) {
          setActiveImageTag(null);
        }
      }}
      style={{ left: `calc(${left}% - 10px)`, top: `calc(${top}% - 25px)` }}
    >
      {isTooltipVisible && (
        <div className={classes.tooltip}>
          <span>{striptags(tag.textContent)}</span>
        </div>
      )}
      <div className={classes.inner}>
        <span>{tag.User.name[0]}</span>
      </div>
    </div>
  );
}
