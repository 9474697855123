/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { API_URL } from '../../../constants/main';
import classes from './styles.module.scss';

const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function EmailMultiInput({
  label,
  required,
  placeholder,
  setFieldValue,
  name,
  onBlur,
}) {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailsStatus, setEmailsStatus] = useState({});

  // eslint-disable-next-line no-shadow
  const checkEmail = async (email) => {
    try {
      const response = await axios.get(`${API_URL}/users/${email}/verify`);
      setEmailsStatus((prevState) => {
        return { ...prevState, [email]: response.data.exists };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addEmail = () => {
    if (!isEmail(email)) {
      return;
    }
    if (emails.includes(email)) {
      setEmail('');
      return;
    }
    setEmails((prevValue) => [...prevValue, email.toLowerCase()]);
    checkEmail(email);
    setEmail('');
  };

  useEffect(() => {
    setFieldValue(name, emails.join(','));
  }, [emails, name, setFieldValue]);

  return (
    <div className={classes.EmailMultiInput}>
      <label htmlFor="emailInput">
        {label}
        {required && <span className={classes.required}>*</span>}
      </label>
      <input
        className={emails.length ? classes.emailsNotEmpty : ''}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            addEmail();
          }
        }}
        placeholder={placeholder}
        id="emailInput"
        type="text"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        onBlur={onBlur}
        name={name}
      />
      {emails.length ? (
        <div className={classes.emails}>
          <ul>
            {emails.map((mail) => {
              return (
                <li key={mail} className={emailsStatus[mail] && classes.active}>
                  {mail}
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </div>
  );
}
