/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';

import { useQuery } from 'react-query';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import striptags from 'striptags';
import TimeAgo from 'javascript-time-ago';
import classNames from 'classnames';

import sliderButtonIcon from '../../assets/images/ticket/sliderButton.svg';
import gridButtonIcon from '../../assets/images/ticket/gridButton.svg';
import defaultButtonIcon from '../../assets/images/ticket/defaultButton.svg';
import sliderButtonActiveIcon from '../../assets/images/ticket/sliderButtonActive.svg';
import gridButtonActiveIcon from '../../assets/images/ticket/gridButtonActive.svg';
import defaultButtonActiveIcon from '../../assets/images/ticket/defaultButtonActive.svg';

import GridView from '../../components/Ticket/GridView';
import MediaSlider from '../../components/Ticket/MediaSlider';
import Player from '../../components/Ticket/Player';
import Comments from '../../components/Ticket/Comments';
import Image from '../../components/Ticket/Image';
import classes from './styles.module.scss';
import { API_URL, STATIC_URL } from '../../constants/main';
import { UIContext, TicketContext } from '../../context';
import UserService from '../../services/UserService';
import CommentsService from '../../services/CommentsService';

export default function Ticket() {
  const [videoTag, setVideoTag] = useState('');
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isTaggingMode, setIsTaggingMode] = useState(false);
  const [leftMarkPosition, setLeftMarkPosition] = useState(0);
  const [rightMarkPosition, setRightMarkPosition] = useState(0);
  const [getTagsTrigger, setGetTagsTrigger] = useState(Math.random());
  const [commentsMode, setCommentsMode] = useState('feedback');
  const [gridPage, setGridPage] = useState(0);

  // image tag properties
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [startingPoint, setStartingPoint] = useState({
    top: 0,
    left: 0,
    topPercentage: 0,
    leftPercentage: 0,
  });

  const { id } = useParams();

  const timeAgo = new TimeAgo('en-US');

  const videoRef = useRef();
  const mediaViewRef = useRef();
  const leftColumnRef = useRef();
  const imageRef = useRef();
  const editorRef = useRef();
  const editorContainerRef = useRef();

  const { viewMode, setViewMode } = useContext(UIContext);
  const { setSelectedFiles, ticket, setTicket } = useContext(TicketContext);

  const navigate = useNavigate();

  const { data: user } = useQuery('me', UserService.getMe);
  const { data: tags, refetch: refetchTags } = useQuery(
    ['tags', ticket?.Files?.[currentFileIndex]?.id],
    () => CommentsService.getTags(ticket?.Files?.[currentFileIndex]?.id)
  );

  const switchFile = (direction) => {
    if (direction === 'next') {
      setCurrentFileIndex((prevIndex) => {
        if (prevIndex + 1 === ticket.Files?.length) {
          return 0;
        }
        return prevIndex + 1;
      });
    } else if (direction === 'previous') {
      setCurrentFileIndex((prevIndex) => {
        if (prevIndex === 0) {
          return ticket.Files?.length - 1;
        }
        return prevIndex - 1;
      });
    }
  };

  const getTicket = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/tickets/${id}`);
      setTicket(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const updateStatus = async (status) => {
    try {
      await axios.put(`${API_URL}/tickets/${ticket.id}`, { status });
      getTicket();
      setIsTaggingMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTicket();
  }, [getTicket, id]);

  useEffect(() => {
    return () => {
      setViewMode('default');
      setSelectedFiles([]);
    };
  }, [setSelectedFiles, setViewMode]);

  useEffect(() => {
    return () => {
      setTicket({});
    };
  }, [setTicket]);

  let currentStatusName = ticket.status;

  if (currentStatusName === 'editrequests') {
    currentStatusName = 'Edit Requests';
  } else if (currentStatusName === 'readyforreview') {
    currentStatusName = 'Ready For Review';
  }

  const switchGridPage = (direction) => {
    const maxPage = Math.ceil(ticket?.Files?.length / 6) - 1;
    if (direction === 'next') {
      setGridPage((prevPage) => {
        if (prevPage === maxPage) {
          return 0;
        }
        return prevPage + 1;
      });
    } else {
      setGridPage((prevPage) => {
        if (prevPage === 0) {
          return maxPage;
        }
        return prevPage - 1;
      });
    }
  };

  const switchSlider = (event) => {
    if (event.deltaY > 0) {
      setCurrentFileIndex((prevIndex) => {
        if (prevIndex < ticket.Files?.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    } else {
      setCurrentFileIndex((prevIndex) => {
        if (prevIndex > 0) {
          return prevIndex - 1;
        }
        return prevIndex;
      });
    }
  };

  return (
    <div
      className={classNames(classes.Ticket, isTaggingMode && classes.tagging)}
    >
      <div className={classes.leftColumn} ref={leftColumnRef}>
        <div
          className={classNames(
            classes.switchButtons,
            viewMode === 'slider' && classes.slider
          )}
        >
          {!isTaggingMode && (
            <>
              {viewMode !== 'slider' && (
                <button
                  type="button"
                  className={classNames(
                    classes.fileSwitchButton,
                    classes.previous
                  )}
                  onClick={() => {
                    if (viewMode === 'default') {
                      switchFile('previous');
                    } else if (viewMode === 'grid') {
                      switchGridPage('previous');
                    }
                  }}
                >
                  <span>View More</span>
                </button>
              )}
              <div className={classes.viewModeSwitcher}>
                <button type="button" onClick={() => setViewMode('slider')}>
                  <img
                    src={
                      viewMode === 'slider'
                        ? sliderButtonActiveIcon
                        : sliderButtonIcon
                    }
                    alt="Slider"
                  />
                </button>

                <button type="button" onClick={() => setViewMode('grid')}>
                  <img
                    src={
                      viewMode === 'grid'
                        ? gridButtonActiveIcon
                        : gridButtonIcon
                    }
                    alt="Grid"
                  />
                </button>
                <button type="button" onClick={() => setViewMode('default')}>
                  <img
                    src={
                      viewMode === 'default'
                        ? defaultButtonActiveIcon
                        : defaultButtonIcon
                    }
                    alt="Default"
                  />
                </button>
              </div>
              {viewMode !== 'slider' && (
                <button
                  type="button"
                  className={classNames(classes.fileSwitchButton, classes.next)}
                  onClick={() => {
                    if (viewMode === 'default') {
                      switchFile('next');
                    } else if (viewMode === 'grid') {
                      switchGridPage('next');
                    }
                  }}
                >
                  <span>View More</span>
                </button>
              )}
            </>
          )}
        </div>
        {viewMode === 'default' && (
          <div className={classes.mediaView} ref={mediaViewRef}>
            {ticket?.Files?.length ? (
              <>
                {ticket?.Files?.[currentFileIndex]?.type === 'image' ? (
                  <Image
                    src={`${STATIC_URL}${ticket?.Files?.[currentFileIndex]?.path}`}
                    alt={ticket.Files?.[currentFileIndex]?.name}
                    mediaViewRef={mediaViewRef}
                    fileId={ticket?.Files?.[currentFileIndex]?.id}
                    width={width}
                    setWidth={setWidth}
                    height={height}
                    setHeight={setHeight}
                    imageRef={imageRef}
                    startingPoint={startingPoint}
                    setStartingPoint={setStartingPoint}
                    editorRef={editorRef}
                    editorContainerRef={editorContainerRef}
                    commentsMode={commentsMode}
                    tags={tags}
                  />
                ) : (
                  <Player
                    fileId={ticket?.Files?.[currentFileIndex]?.id}
                    videoUrl={ticket?.Files?.[currentFileIndex]?.path}
                    videoTag={videoTag}
                    setVideoTag={setVideoTag}
                    videoRef={videoRef}
                    leftMarkPosition={leftMarkPosition}
                    setLeftMarkPosition={setLeftMarkPosition}
                    rightMarkPosition={rightMarkPosition}
                    setRightMarkPosition={setRightMarkPosition}
                    getTagsTrigger={getTagsTrigger}
                    commentsMode={commentsMode}
                    height={height}
                    setHeight={setHeight}
                  />
                )}
              </>
            ) : (
              <div>No content</div>
            )}
          </div>
        )}
        {viewMode === 'grid' && (
          <GridView ticket={ticket} gridPage={gridPage} />
        )}
        {viewMode === 'slider' && (
          <MediaSlider
            currentFileIndex={currentFileIndex}
            setCurrentFileIndex={setCurrentFileIndex}
            switchSlider={switchSlider}
            ticket={ticket}
          />
        )}
        <div className={classes.ticketInfo}>
          <header>
            <h1>{ticket.name}</h1>
            {ticket.createdAt && (
              <span className={classes.createdAt}>
                {timeAgo.format(new Date(ticket.createdAt))}
              </span>
            )}
            <div
              className={classes.statusContainer}
              onClick={() => setIsTaggingMode(true)}
            >
              <button
                type="button"
                className={classes.uploadButton}
                onClick={() => navigate(`/tickets/${id}/edit`)}
              >
                Upload
              </button>
              <div
                className={classNames(classes.status, classes[ticket.status])}
              />
              <span>{currentStatusName}</span>
            </div>
          </header>
          {!isTaggingMode && (
            <>
              <div className={classes.info}>
                <span className={classes.priority}>{ticket.priority}</span>
                <div className={classes.authorInfo}>
                  <div className={classes.userPic}>{ticket.User?.name[0]}</div>
                  <div className={classes.authorName}>
                    <span className={classes.name}>{ticket.User?.name}</span>
                    <span className={classes.role}>Author</span>
                  </div>
                </div>
                <div className={classes.description}>
                  {striptags(ticket.description)}
                </div>
              </div>
              <div />
            </>
          )}
          {isTaggingMode && (
            <div className={classes.info}>
              <span className={classes.priority}>{ticket.priority}</span>
              <div className={classes.authorInfo}>
                <div className={classes.userPic}>{ticket.User?.name[0]}</div>
                <div className={classes.authorName}>
                  <span className={classes.name}>{ticket.User?.name}</span>
                  <span className={classes.role}>Author</span>
                </div>
              </div>
              <div className={classes.description}>
                {striptags(ticket.description)}
              </div>
            </div>
          )}
          {isTaggingMode && (
            <div className={classes.statusSelector}>
              <ul>
                <li
                  className={classNames(
                    classes.brief,
                    ticket.status === 'brief' && classes.active
                  )}
                  onClick={() => updateStatus('brief')}
                >
                  <div className={classes.statusIcon} />
                  <span className={classes.statusName}>Brief</span>
                </li>
                <li
                  className={classNames(
                    classes.production,
                    ticket.status === 'production' && classes.active
                  )}
                  onClick={() => updateStatus('production')}
                >
                  <div className={classes.statusIcon} />
                  <span className={classes.statusName}>Production</span>
                </li>
                <li
                  className={classNames(
                    classes.readyforreview,
                    ticket.status === 'readyforreview' && classes.active
                  )}
                  onClick={() => updateStatus('readyforreview')}
                >
                  <div className={classes.statusIcon} />
                  <span className={classes.statusName}>Ready For Review</span>
                </li>
                {user.companyType.includes('Agency') && (
                  <>
                    <li
                      className={classNames(
                        classes.editrequests,
                        ticket.status === 'editrequests' && classes.active
                      )}
                      onClick={() => updateStatus('editrequests')}
                    >
                      <div className={classes.statusIcon} />
                      <span className={classes.statusName}>Edit Requests</span>
                    </li>
                    <li
                      className={classNames(
                        classes.approved,
                        ticket.status === 'approved' && classes.active
                      )}
                      onClick={() => updateStatus('approved')}
                    >
                      <div className={classes.statusIcon} />
                      <span className={classes.statusName}>Approved</span>
                    </li>
                  </>
                )}
                <li
                  className={classNames(
                    classes.completed,
                    ticket.status === 'completed' && classes.active
                  )}
                  onClick={() => updateStatus('completed')}
                >
                  <div className={classes.statusIcon} />
                  <span className={classes.statusName}>Completed</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className={classes.rightColumn}>
        <Comments
          getTagsTrigger={getTagsTrigger}
          parentClasses={classes}
          ticketId={id}
          fileId={ticket?.Files?.[currentFileIndex]?.id}
          videoRef={videoRef}
          commentsMode={commentsMode}
          setCommentsMode={setCommentsMode}
          leftMarkPosition={leftMarkPosition}
          rightMarkPosition={rightMarkPosition}
          setGetTagsTrigger={setGetTagsTrigger}
          imageRef={imageRef}
          width={width}
          setWidth={setWidth}
          height={height}
          startingPoint={startingPoint}
          editorRef={editorRef}
          editorContainerRef={editorContainerRef}
          tags={tags}
          refetchTags={refetchTags}
          user={user}
        />
      </div>
    </div>
  );
}
