import React, { useEffect, useState } from 'react';

import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import classNames from 'classnames';

import videoTimeToHMS from '../../../../helpers/videoTimeToHMS';
import classes from './styles.module.scss';
import './styles.scss';
import linkIcon from '../../../../assets/images/editor/linkLight.svg';
import boldIcon from '../../../../assets/images/editor/boldLight.svg';
import underLineIcon from '../../../../assets/images/editor/underlineLight.svg';
import italicIcon from '../../../../assets/images/editor/italicLight.svg';
import listIcon from '../../../../assets/images/editor/listLight.svg';

const toolbarOptions = {
  options: ['inline', 'link', 'list'],

  inline: {
    options: ['italic', 'bold', 'underline'],
    bold: { icon: boldIcon, className: classes.toolbarIcon },
    underline: { icon: underLineIcon, className: classes.toolbarIcon },
    italic: { icon: italicIcon, className: classes.toolbarIcon },
  },
  link: {
    options: ['link'],
    link: { icon: linkIcon, className: classes.toolbarIcon },
    showOpenOptionOnHover: false,
  },
  list: {
    options: ['unordered'],
    unordered: { icon: listIcon, className: classes.toolbarIcon },
  },
};

export default function ReplyBox({
  onSubmit,
  isNested,
  commentText,
  setCommentsMode,
  rightMarkPosition,
  leftMarkPosition,
  videoRef,
  commentsMode,
  createTagMutate,
  fileId,
  activeWorkspace,
  createImageTagMutate,
  imageRef,
  width,
  height,
  startingPoint,
  editorRef,
  editorContainerRef,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);
  const [htmlContent, setHtmlContent] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const changeEditorState = (state) => {
    setEditorState(state);
  };

  useEffect(() => {
    const html = convertToHTML(editorState.getCurrentContent());
    setHtmlContent(html);
    // eslint-disable-next-line no-param-reassign
    commentText.current = html;
  }, [commentText, editorState]);

  useEffect(() => {
    if (!isFocused && (!htmlContent || htmlContent === '<p></p>')) {
      setIsPlaceholderVisible(true);
    }
    if (isFocused) {
      setIsPlaceholderVisible(false);
    }
  }, [isFocused, htmlContent]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (commentText.current.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '')) {
        if (commentsMode === 'tags') {
          if (videoRef.current) {
            createTagMutate({
              videoTag: commentText.current,
              leftMarkPosition,
              rightMarkPosition,
              videoRef,
              fileId,
              activeWorkspace,
            });
          } else {
            console.log(createImageTagMutate);
            createImageTagMutate({
              tagText: commentText.current,
              imageRef,
              width,
              height,
              startingPoint,
              fileId,
            });
          }
        } else {
          onSubmit();
        }
      }
    }
  };

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFocused]);

  return (
    <div
      className={classNames(classes.ReplyBox, isNested && classes.isNested)}
      ref={editorContainerRef}
    >
      <div className={classes.editorComponent}>
        <div
          className={classes.placeholder}
          style={{
            visibility: isPlaceholderVisible ? 'visible' : 'hidden',
          }}
        >
          Please enter a description for the brief
        </div>
        <Editor
          ref={editorRef}
          toolbar={toolbarOptions}
          editorState={editorState}
          onEditorStateChange={changeEditorState}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
      <div
        className={classes.buttonContainer}
        style={{ display: isNested ? 'none' : 'block' }}
      >
        <button
          className={classNames(
            classes.tagsButton,
            commentsMode === 'tags' && classes.taggingMode
          )}
          type="button"
          onClick={() => {
            setCommentsMode((prevMode) => {
              if (prevMode === 'tags') {
                return 'feedback';
              }
              return 'tags';
            });
          }}
        >
          {commentsMode === 'tags' ? (
            <>
              {videoRef.current ? (
                <p className={classes.time}>
                  <span
                    style={{
                      display:
                        leftMarkPosition === rightMarkPosition ? 'none' : '',
                    }}
                  >
                    {videoTimeToHMS(
                      (leftMarkPosition / 100) * videoRef?.current?.duration
                    )}{' '}
                    -
                  </span>{' '}
                  <span>
                    {videoTimeToHMS(
                      (rightMarkPosition / 100) * videoRef?.current?.duration
                    )}
                  </span>
                </p>
              ) : (
                <span>Tag</span>
              )}
            </>
          ) : (
            'Tag'
          )}
        </button>
      </div>
    </div>
  );
}
