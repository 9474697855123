/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';

import classNames from 'classnames';

import { TicketContext } from '../../../context';
import { STATIC_URL } from '../../../constants/main';
import classes from './styles.module.scss';

export default function GridView({ ticket, gridPage }) {
  const gridFiles = ticket?.Files?.slice(gridPage * 6, gridPage * 6 + 6);

  const { selectedFiles, setSelectedFiles } = useContext(TicketContext);

  const toggleSelection = (fileId) => {
    setSelectedFiles((prevFileIds) => {
      if (prevFileIds.includes(fileId)) {
        return prevFileIds.filter(
          (existingFileId) => fileId !== existingFileId
        );
      }
      return [...prevFileIds, fileId];
    });
  };

  return (
    <div className={classes.GridView}>
      {gridFiles.map((file) => {
        return (
          <div key={file.id} className={classes.file}>
            <div
              className={classes.media}
              onClick={() => toggleSelection(file.id)}
            >
              <div
                className={classNames(
                  classes.checkbox,
                  selectedFiles.includes(file.id) && classes.active
                )}
              />
              {file.type === 'image' ? (
                <img alt={file.name} src={STATIC_URL + file.path} />
              ) : (
                <video src={STATIC_URL + file.path} />
              )}
            </div>
            <footer>{file.name}</footer>
          </div>
        );
      })}
    </div>
  );
}
