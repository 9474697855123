import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { UIContext, TicketContext } from '../context';

import PrivateRoute from '../containers/PrivateRoute';
import SignUp from '../pages/SignUp';
import SignIn from '../pages/SignIn';
import Layout from '../containers/Layout';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/ProfileNew';
import ForgotPassword from '../pages/ForgotPassword';
import AddOrEditTicket from '../pages/AddOrEditTicket';
import Ticket from '../pages/Ticket';
import AddWorkspace from '../pages/AddWorkspace';
import { API_URL } from '../constants/main';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppRoutes = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeWorkspace, setActiveWorkspace] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [areWorkspacesFetching, setAreWorkspacesFetching] = useState(true);
  const [viewMode, setViewMode] = useState('default');

  const [activeVideoTag, setActiveVideoTag] = useState(null);
  const [activeImageTag, setActiveImageTag] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticket, setTicket] = useState({});

  const notifyError = useCallback((message) => toast.error(message), []);
  const notifySuccess = useCallback((message) => toast.success(message), []);

  const sidebarRef = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const getWorkspaces = useCallback(async () => {
    try {
      setAreWorkspacesFetching(true);
      const response = await axios.get(`${API_URL}/workspaces`);
      setWorkspaces(response.data);
      setAreWorkspacesFetching(false);
    } catch (error) {
      console.log(error);
      setAreWorkspacesFetching(false);
    }
  }, []);

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsSidebarHidden(true);
    } else {
      setIsSidebarHidden(false);
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (
      !activeWorkspace ||
      !workspaces.find((workspace) => workspace.id === activeWorkspace.id)
    ) {
      let previousWorkspace = {};

      try {
        previousWorkspace = JSON.parse(localStorage.getItem('workspace'));
      } catch (error) {
        console.log(error);
      }

      setActiveWorkspace(
        workspaces.some((wrkspc) => wrkspc?.id === previousWorkspace?.id)
          ? previousWorkspace
          : workspaces[0]
      );
    }
  }, [workspaces, activeWorkspace, setActiveWorkspace]);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UIContext.Provider
          value={useMemo(
            () => ({
              notifyError,
              notifySuccess,
              searchQuery,
              setSearchQuery,
              sidebarRef,
              isSidebarHidden,
              setIsSidebarHidden,
              isSidebarCollapsed,
              setIsSidebarCollapsed,
              activeWorkspace,
              setActiveWorkspace,
              workspaces,
              setWorkspaces,
              getWorkspaces,
              areWorkspacesFetching,
              setAreWorkspacesFetching,
              viewMode,
              setViewMode,
            }),
            [
              notifyError,
              notifySuccess,
              searchQuery,
              isSidebarHidden,
              isSidebarCollapsed,
              activeWorkspace,
              workspaces,
              getWorkspaces,
              areWorkspacesFetching,
              viewMode,
            ]
          )}
        >
          <TicketContext.Provider
            value={useMemo(
              () => ({
                activeVideoTag,
                setActiveVideoTag,
                activeImageTag,
                setActiveImageTag,
                selectedFiles,
                setSelectedFiles,
                ticket,
                setTicket,
              }),
              [activeImageTag, activeVideoTag, selectedFiles, ticket]
            )}
          >
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels/facebook"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard channel="facebook" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels/instagram"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard channel="instagram" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels/youtube"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard channel="youtube" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels/snapchat"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard channel="snapchat" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/channels/tiktok"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard channel="tiktok" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brief"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="brief" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/review"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="readyforreview" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/editrequests"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="editrequests" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/production"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="production" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/approved"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="approved" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/completed"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Dashboard status="completed" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <Profile />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/add-ticket"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <AddOrEditTicket />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/tickets/:id"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Ticket />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/tickets/:id/edit"
                element={
                  <PrivateRoute>
                    <Layout noHeader>
                      <AddOrEditTicket />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/add-workspace"
                element={
                  <PrivateRoute>
                    <Layout headerBackButton>
                      <AddWorkspace />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </TicketContext.Provider>
        </UIContext.Provider>
        <ToastContainer />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
