/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classes from './styles.module.scss';

export default function TextInput({
  label,
  placeholder,
  maxLength = 200,
  onChange,
  name,
  onBlur,
  value,
  type,
  width,
}) {
  return (
    <label className={classes.TextInput} style={{ width }}>
      <span className={classes.label}>{label}</span>
      <input
        style={{ width }}
        type={type || 'text'}
        name={name}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
      />
      <div className={classes.maxCharacters}>Maximo {maxLength} caracteres</div>
    </label>
  );
}
