/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';

import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Select from '../../components/Form/Select';
import TextInput from '../../components/Form/TextInput';
import Editor from '../../components/Form/Editor';
import MultiSelect from '../../components/Form/MultiSelect';
import { API_URL } from '../../constants/main';
import { UIContext } from '../../context';
import getDocumentHeight from '../../helpers/getDocumentHeight';

import classes from './styles.module.scss';

const validationSchema = yup.object({
  name: yup.string().trim().required('This field is required'),
  type: yup.string().trim().required('This field is required'),
  description: yup
    .string()
    .trim()
    .required('This field is required')
    .max(600, 'Too long')
    .notOneOf(['<p></p>'], 'This field is required'),
  channels: yup.string().required('This field is required'),
  priority: yup.string().required('This field is required'),
});

export default function AddOrEditTicket() {
  const [ticket, setTicket] = useState({});
  const [files, setFiles] = useState([]);
  const [isTicketCreated, setIsTicketCreated] = useState(false);

  const initialValues = {
    name: ticket.name || '',
    type: ticket.type || 'Static',
    description: ticket.description || '',
    channels: ticket.channels || '',
    priority: ticket.priority || '',
  };

  const { id } = useParams();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    async function getTicket() {
      try {
        const response = await axios.get(`${API_URL}/tickets/${id}`);
        setTicket(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (id) {
      getTicket();
    }
  }, [id]);

  const { notifyError, activeWorkspace } = useContext(UIContext);

  const createOrUpdateTicket = async (values) => {
    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (!id) {
        formData.append('workspaceId', activeWorkspace.id);
      }

      const images = [...files].filter((file) => file.type.includes('image'));
      const videos = [...files].filter((file) => file.type.includes('video'));

      images.forEach((image) => {
        formData.append('image', image);
      });

      videos.forEach((video) => {
        formData.append('video', video);
      });

      if (id) {
        await axios.put(`${API_URL}/tickets/${id}`, formData);
      } else {
        await axios.post(`${API_URL}/tickets`, formData);
      }

      setIsTicketCreated(true);
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: createOrUpdateTicket,
    enableReinitialize: true,
  });

  const navigate = useNavigate();

  const filterFiles = (fileName) => {
    setFiles((prevFiles) =>
      [...prevFiles].filter((file) => file.name !== fileName)
    );
  };

  useEffect(() => {
    document.querySelector(
      `.${classes.AddOrEditTicket}`
    ).style.minHeight = `${getDocumentHeight()}px`;
  }, []);

  return (
    <div className={classes.AddOrEditTicket}>
      <div className={classes.background} />
      <div className={classes.container}>
        <header className={classes.header}>
          <button
            className={classes.closeButton}
            type="button"
            onClick={() => navigate(-1)}
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.16992 6.83001L6.82992 1.17001"
                stroke="#EDEEFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.82992 6.83001L1.16992 1.17001"
                stroke="#EDEEFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </header>
        <form onSubmit={formik.handleSubmit}>
          {isTicketCreated && (
            <div className={classes.success}>
              <div className={classes.successIcon}>
                <svg
                  width="40"
                  height="28"
                  viewBox="0 0 40 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.29163 14L14.0833 25.7917L37.7083 2.20837"
                    stroke="#5F6073"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className={classes.congratulations}>Congratulations</p>
              <p className={classes.cerrar} onClick={() => navigate(-1)}>
                Cerrar
              </p>
            </div>
          )}
          <div className={classes.leftColumn}>
            <div className={classes.inputContainer}>
              <TextInput
                label="Name"
                name="name"
                placeholder="Ingrese el nombre de la idea"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width={isTabletOrMobile ? 290 : ''}
              />
              {formik.errors.name && formik.touched.name && (
                <div className={classes.error}>{formik.errors.name}</div>
              )}
            </div>
            <div className={classes.radiosContainer}>
              <p className={classes.label}>Type</p>
              <div className={classes.radios}>
                <label>
                  Static
                  <input
                    onClick={() => formik.setFieldValue('type', 'Static')}
                    type="radio"
                    name="type"
                    checked={formik.values.type === 'Static'}
                  />
                </label>
                <label>
                  Video
                  <input
                    onClick={() => formik.setFieldValue('type', 'Video')}
                    checked={formik.values.type === 'Video'}
                    type="radio"
                    name="type"
                  />
                </label>
              </div>
              {formik.errors.companyType && formik.touched.companyType && (
                <div className={classes.error}>{formik.errors.companyType}</div>
              )}
            </div>
            <div className={classes.editorContainer}>
              <p className={classes.label}>Description</p>
              <Editor
                setFieldValue={formik.setFieldValue}
                name="description"
                value={formik.values.description}
                setTouched={formik.setTouched}
                touched={formik.touched}
                setFiles={setFiles}
                initialState={ticket.description || null}
              />
              {formik.errors.description && formik.touched.description && (
                <div className={classes.error}>{formik.errors.description}</div>
              )}
              <div className={classes.files}>
                {Array.from(files).map((file) => {
                  return (
                    <div className={classes.file} key={file.name}>
                      <span>{file.name}</span>
                      <button
                        type="button"
                        onClick={() => filterFiles(file.name)}
                      >
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.16992 6.83001L6.82992 1.17001"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.82992 6.83001L1.16992 1.17001"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.selectContainer}>
              <MultiSelect
                label="Channels"
                options={[
                  { name: 'Facebook' },
                  { name: 'Instagram' },
                  { name: 'YouTube' },
                  { name: 'Snapchat' },
                  { name: 'TikTok' },
                ]}
                name="channels"
                value={formik.values.channels}
                setFieldValue={formik.setFieldValue}
                placeholder="Seleccione una categoria"
                width={isTabletOrMobile ? 290 : ''}
              />
              {formik.errors.channels && formik.touched.channels && (
                <div className={classes.error}>{formik.errors.channels}</div>
              )}
            </div>
            <div className={classes.selectContainer}>
              <Select
                label="Priority"
                options={['P0', 'P1', 'P2', 'P3']}
                name="priority"
                value={formik.values.priority}
                setFieldValue={formik.setFieldValue}
                setTouched={formik.setTouched}
                touched={formik.touched}
                width={isTabletOrMobile ? 290 : ''}
              />
              {formik.errors.priority && formik.touched.priority && (
                <div className={classes.error}>{formik.errors.priority}</div>
              )}
            </div>
            <button className={classes.submitButton} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
