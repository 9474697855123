/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext, createRef } from 'react';

import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import Header from '../../components/Layout/Header';
import Notifications from '../../components/Dashboard/Notifications';

import useResizeObserver from '../../hooks/useResizeObserver';
import noWorkspacesImage from '../../assets/images/emptyScreenBackground.svg';
import CardsRow from './CardsRow';
import classes from './styles.module.scss';
import { UIContext } from '../../context';
import getDocumentHeight from '../../helpers/getDocumentHeight';
import TicketsService from '../../services/TicketsService';

let timer;

export default function Dashboard({ channel, status }) {
  const [isRightSidebarCollapsed, setIsRightsidebarCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [allTickets, setAllTickets] = useState([]);

  const { searchQuery, activeWorkspace } = useContext(UIContext);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const noWorkspacesContainerRef = createRef();
  const { width } = useResizeObserver(noWorkspacesContainerRef);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchTerm(searchQuery);
    }, 400);
  }, [searchQuery]);

  useEffect(() => {
    document.querySelector(
      `.${classes.Dashboard}`
    ).style.minHeight = `${getDocumentHeight()}px`;
  }, []);

  useEffect(() => {
    if (activeWorkspace) {
      TicketsService.getAllTickets({
        activeWorkspace,
      }).then((data) => {
        setAllTickets(data);
      });
    }
  }, [status, activeWorkspace]);

  const { data: staticTickets, refetch: refetchStaticTickets } = useQuery(
    ['staticTickets', status, searchTerm, channel, activeWorkspace],
    () =>
      TicketsService.getTickets({
        type: 'Static',
        status,
        searchTerm,
        channel,
        activeWorkspace,
      }),
    {
      enabled: !!activeWorkspace,
      keepPreviousData: true,
    }
  );

  const {
    data: videoTickets,
    refetch: refetchVideoTickets,
    isLoading: areVideoTicketsLoading,
  } = useQuery(
    ['videoTickets', status, searchTerm, channel, activeWorkspace],
    () =>
      TicketsService.getTickets({
        type: 'Video',
        status,
        searchTerm,
        channel,
        activeWorkspace,
      }),
    {
      enabled: !!activeWorkspace,
      keepPreviousData: true,
    }
  );

  /*  const getTickets = useCallback(
        async (type) => {
          if (!activeWorkspace) {
            return;
          }
          try {
            const response = await axios.get(`${API_URL}/tickets`, {
              params: {
                type,
                searchQuery: searchTerm,
                channel,
                status,
                workspaceId: activeWorkspace.id,
              },
            });
            if (type === 'Static') {
              setStaticCards(response.data);
            } else if (type === 'Video') {
              setVideoCards(response.data);
            }
          } catch (error) {
            console.log(error);
          }
        },
        [searchTerm, channel, status, activeWorkspace]
      );
    
      useEffect(() => {
        getTickets('Video');
        getTickets('Static');
      }, [getTickets]); */
  return (
    <div className={classes.Dashboard}>
      <div className={classes.container}>
        <header>
          <Header />
        </header>
        {(!staticTickets || !staticTickets?.length) &&
        (!videoTickets || !videoTickets?.length) &&
        !areVideoTicketsLoading &&
        !allTickets.length > 0 ? (
          <div className={classes.noBriefs} ref={noWorkspacesContainerRef}>
            <h1>Welcome!</h1>
            <p>
              You haven&apos;t created any new briefs yet, Click the button
              above to create one.
              {width && (
                <span
                  className={classes.arrow}
                  style={{
                    width: width / 2 - 155 - 101,
                    right: -(width / 2 - 155 - 101),
                  }}
                />
              )}
            </p>
            <img src={noWorkspacesImage} alt="" />
          </div>
        ) : (
          <div
            className={classNames(
              classes.cardsContainer,
              isRightSidebarCollapsed && classes.collapsed
            )}
          >
            <CardsRow
              cards={staticTickets}
              classes={classes}
              getTickets={refetchStaticTickets}
            />
            <CardsRow
              cards={videoTickets}
              classes={classes}
              video
              getTickets={refetchVideoTickets}
            />
          </div>
        )}
      </div>
      {!isTabletOrMobile && (
        <div className={classes.rightSidebarContainer}>
          <Notifications
            isCollapsed={isRightSidebarCollapsed}
            setIsCollapsed={setIsRightsidebarCollapsed}
          />
        </div>
      )}
    </div>
  );
}
