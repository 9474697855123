import React, { useContext } from 'react';

import { useLocation } from 'react-router-dom';

import { STATIC_URL } from '../../../../constants/main';
import { TicketContext } from '../../../../context';
import classes from './styles.module.scss';

export default function DownloadButton() {
  const { pathname } = useLocation();

  const isTicket = pathname.includes('ticket');

  const { ticket, selectedFiles: selectedFilesIds } = useContext(TicketContext);

  const selectedFiles = selectedFilesIds.length
    ? ticket?.Files?.filter((file) => selectedFilesIds.includes(file.id))
    : ticket?.Files;

  const downloadFromURI = async (url, name) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = link;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadSelectedFiles = () => {
    selectedFiles.forEach((file) => {
      downloadFromURI(STATIC_URL + file.path, file.name);
    });
  };

  return (
    <button
      type="button"
      className={classes.DownloadButton}
      style={{ display: isTicket ? 'flex' : 'none' }}
      onClick={downloadSelectedFiles}
    >
      Download
    </button>
  );
}
