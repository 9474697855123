import axios from 'axios';

import { API_URL } from '../constants/main';

const UserService = {
  async getMe() {
    const response = await axios.get(`${API_URL}/users/me`);
    return response.data;
  },
  async updateMe(values) {
    const response = await axios.patch('/users/me', values);
    return response.data;
  },
  async uploadPhoto(image) {
    const form = new FormData();
    form.append('file', image);
    const response = await axios.post('/users/me/photo', form);
    return response.data;
  },
};

export default UserService;
