/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function MultiSelect({
  label,
  options,
  setFieldValue,
  // value,
  name,
  setTouched,
  touched,
  width,
  type,
  required,
  placeholder,
}) {
  const [isOptionsContainerVisible, setIsOptionsContainerVisible] =
    useState(false);
  const [values, setValues] = useState([]);

  const uiElementsRef = useRef();

  useOnClickOutside(uiElementsRef, () => {
    setIsOptionsContainerVisible(false);
    if (isOptionsContainerVisible) {
      setTouched({ ...touched, [name]: true });
    }
  });

  // eslint-disable-next-line no-shadow
  const toggleValue = (value) => {
    setValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((val) => val !== value);
      }
      return [...prevValues, value];
    });
  };

  useEffect(() => {
    setFieldValue(name, values.join(','));
  }, [name, setFieldValue, values]);

  let selectorText = 'Please select';

  if (placeholder) {
    selectorText = placeholder;
  }

  if (values.length) {
    selectorText = values.join(', ');
  }

  return (
    <label className={classes.MultiSelect}>
      <span className={classes.label}>
        {label}
        {required && <span className={classes.required}>*</span>}
      </span>
      <div ref={uiElementsRef}>
        <div
          className={classNames(
            classes.selector,
            classes[type],
            isOptionsContainerVisible && classes.open
          )}
          style={{ width }}
          onClick={() => {
            setIsOptionsContainerVisible((prevState) => !prevState);
          }}
        >
          <span>{selectorText}</span>
        </div>
        {isOptionsContainerVisible && (
          <div
            className={classNames(classes.options, classes[type])}
            style={{ width }}
          >
            {options?.map((option) => {
              let optionClass = classes[option.toLowerCase()];

              if (option === 'Creativity Agency') {
                optionClass = classes.creativityAgency;
              }

              return (
                <div
                  className={classNames(
                    classes.option,
                    optionClass,
                    values.includes(option) && classes.active
                  )}
                  key={option}
                  onClick={() => {
                    toggleValue(option);
                  }}
                >
                  {option}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </label>
  );
}
