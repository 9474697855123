/* eslint-disable consistent-return */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';

import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from 'react-query';

import CommentsService from '../../../services/CommentsService';
import Comment from './Comment';
import ReplyBox from './ReplyBox';
import classes from './styles.module.scss';
import { API_URL } from '../../../constants/main';
import { UIContext, TicketContext } from '../../../context';

export default function Comments({
  ticketId,
  parentClasses,
  fileId,
  videoRef,
  commentsMode,
  setCommentsMode,
  leftMarkPosition,
  rightMarkPosition,
  imageRef,
  width,
  setWidth,
  height,
  startingPoint,
  editorRef,
  editorContainerRef,
  tags,
  refetchTags,
  user,
}) {
  const [feedback, setFeedback] = useState([]);
  const [commentsHeight, setCommentsHeight] = useState('');
  const [replyBoxKey, setReplyBoxKey] = useState(Math.random());

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const commentsRef = useRef();
  const commentText = useRef();

  const { isSidebarCollapsed, notifyError, notifySuccess, activeWorkspace } =
    useContext(UIContext);
  const { viewMode } = useContext(TicketContext);

  const getComments = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/comments/${ticketId}`);
      setFeedback(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [ticketId]);

  const { mutate: createVideoTagMutate } = useMutation(
    CommentsService.createVideoTag,
    {
      onSuccess: () => {
        setReplyBoxKey(Math.random());
        setReplyBoxKey(Math.random());
        refetchTags();
        notifySuccess('Tag has been created');
      },
      onError: (error) => {
        console.log(error);
        notifyError(error?.response?.data?.message);
      },
    }
  );

  const { mutate: createImageTagMutate } = useMutation(
    CommentsService.createImageTag,
    {
      onSuccess: () => {
        setWidth(0);
        setReplyBoxKey(Math.random());
        refetchTags();
        notifySuccess('Tag has been created');
      },
      onError: (error) => {
        console.log(error);
        notifyError(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    const changeHeight = () => {
      if (isTabletOrMobile) {
        return;
      }
      setCommentsHeight(document.body.offsetHeight);
    };

    window.addEventListener('resize', changeHeight);

    setTimeout(() => {
      changeHeight();
    }, 300);

    return () => {
      window.removeEventListener('resize', changeHeight);
    };
  }, [isTabletOrMobile, isSidebarCollapsed, viewMode]);

  const createComment = async () => {
    try {
      if (
        commentText.current.trim() === '' ||
        commentText.current === '<p></p>'
      ) {
        return;
      }

      await axios.post(`${API_URL}/comments`, {
        ticketId,
        textContent: commentText.current,
      });
      setReplyBoxKey(Math.random());
      getComments();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getComments();
  }, [getComments]);

  useEffect(() => {
    if (isTabletOrMobile) {
      const parent = document.body.querySelector(
        `.${parentClasses.leftColumn}`
      );
      setTimeout(() => {
        commentsRef.current.style.setProperty(
          '--parentHeight',
          `${parent.offsetHeight}px`
        );
      }, 100);
    }
  }, [parentClasses, isTabletOrMobile, ticketId]);

  const comments = commentsMode === 'tags' ? tags : feedback;

  return (
    <div
      className={classes.Comments}
      style={{ minHeight: `calc(${commentsHeight}px - 121px)` }}
      ref={commentsRef}
    >
      <header className={classes.header}>
        <ReplyBox
          key={replyBoxKey}
          value={commentText}
          commentText={commentText}
          onSubmit={createComment}
          setCommentsMode={setCommentsMode}
          commentsMode={commentsMode}
          videoRef={videoRef}
          leftMarkPosition={leftMarkPosition}
          rightMarkPosition={rightMarkPosition}
          createTagMutate={createVideoTagMutate}
          createImageTagMutate={createImageTagMutate}
          fileId={fileId}
          activeWorkspace={activeWorkspace}
          imageRef={imageRef}
          width={width}
          height={height}
          startingPoint={startingPoint}
          editorRef={editorRef}
          editorContainerRef={editorContainerRef}
        />
      </header>
      <div className={classes.commentsContainer}>
        {comments
          ?.filter((comment) => !comment.commentId)
          .map((comment) => {
            return (
              <Comment
                parentClasses={parentClasses}
                key={comment.id}
                commentData={comment}
                getComments={getComments}
                comments={comments}
                refetchTags={refetchTags}
                user={user}
              />
            );
          })}
      </div>
    </div>
  );
}
