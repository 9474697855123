import React, { useState, useCallback, useContext } from 'react';

import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Modal from 'react-bootstrap/Modal';

import classNames from 'classnames';
import Editor from '../../components/AuthForm/Editor';
import Select from '../../components/AuthForm/Select';
import MultiSelect from '../../components/AuthForm/MultiSelect';
import TextInput from '../../components/AuthForm/TextInput';
import { API_URL } from '../../constants/main';
import { UIContext } from '../../context';
import classes from './styles.module.scss';

const validationSchemaFirstStep = yup.object({
  name: yup.string().trim().required('This field is required'),
  description: yup
    .string()
    .trim()
    .required('This field is required')
    .max(600, 'Too long')
    .notOneOf(['<p></p>'], 'This field is required'),
});

const validationSchemaThirdStep = yup.object({
  type: yup.string().trim().required('This field is required'),
  channels: yup.string().required('This field is required'),
  priority: yup.string().required('This field is required'),
});

export default function CreateBriefModal({ show, handleClose }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [briefId, setBriefId] = useState(null);

  const initialValuesFirstStep = {
    name: '',
    description: '',
  };

  const initialValuesThirdStep = {
    type: '',
    channels: '',
    priority: '',
  };

  const formikFirstStep = useFormik({
    initialValues: initialValuesFirstStep,
    validationSchema: validationSchemaFirstStep,
    onSubmit: () => setCurrentStep(2),
  });

  const { activeWorkspace, notifyError } = useContext(UIContext);

  const createBrief = async (values) => {
    try {
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      Object.keys(formikFirstStep.values).forEach((key) => {
        formData.append(key, formikFirstStep.values[key]);
      });

      formData.append('workspaceId', activeWorkspace.id);

      const images = [...files].filter((file) => file.type.includes('image'));
      const videos = [...files].filter((file) => file.type.includes('video'));

      images.forEach((image) => {
        formData.append('image', image);
      });

      videos.forEach((video) => {
        formData.append('video', video);
      });

      const response = await axios.post(`${API_URL}/tickets`, formData);

      setBriefId(response.data.id);
      setCurrentStep(4);
    } catch (error) {
      console.log(error);
      notifyError(error?.response?.data?.message);
    }
  };

  const formikThirdStep = useFormik({
    initialValues: initialValuesThirdStep,
    validationSchema: validationSchemaThirdStep,
    onSubmit: createBrief,
  });

  const closeModal = () => {
    setTimeout(() => {
      setCurrentStep(1);
      formikFirstStep.handleReset();
    }, 300);
    handleClose();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter(
        (file) => !files.some((existingFile) => file.name === existingFile.name)
      );
      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'video/*': ['.mp4', '.webm'],
    },
  });

  const filterFiles = (fileName) => {
    setFiles((prevFiles) =>
      [...prevFiles].filter((file) => file.name !== fileName)
    );
  };

  return (
    <Modal
      show={show}
      centered
      contentClassName={classNames(
        classes.CreateBriefModal,
        currentStep === 4 && classes.briefCreated
      )}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      {currentStep !== 4 ? (
        <div className={classes.content}>
          <header>
            <h1>Add Brief</h1>
            <button type="button" onClick={closeModal}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.17004 14.83L14.83 9.17"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.83 14.83L9.17004 9.17"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </header>
          <form>
            {currentStep === 1 && (
              <>
                <div className={classes.inputContainer}>
                  <TextInput
                    label="Name"
                    name="name"
                    placeholder="Please enter the brief name"
                    value={formikFirstStep.values.name}
                    onChange={formikFirstStep.handleChange}
                    onBlur={formikFirstStep.handleBlur}
                    required
                    type="none"
                  />
                  {formikFirstStep.errors.name &&
                    formikFirstStep.touched.name && (
                      <div className={classes.error}>
                        {formikFirstStep.errors.name}
                      </div>
                    )}
                </div>
                <div className={classes.editorContainer}>
                  <p className={classes.label}>
                    Description<span className={classes.required}>*</span>
                  </p>
                  <Editor
                    setFieldValue={formikFirstStep.setFieldValue}
                    name="description"
                    value={formikFirstStep.values.description}
                    setTouched={formikFirstStep.setTouched}
                    touched={formikFirstStep.touched}
                  />
                  {formikFirstStep.errors.description &&
                    formikFirstStep.touched.description && (
                      <div className={classes.error}>
                        {formikFirstStep.errors.description}
                      </div>
                    )}
                </div>
                <button
                  type="button"
                  onClick={formikFirstStep.handleSubmit}
                  className={classes.button}
                >
                  Continue
                </button>
              </>
            )}
            {currentStep === 2 && (
              <div className={classes.dropzoneContainer}>
                <p className={classes.dropzoneLabel}>Media</p>
                <div {...getRootProps()} className={classes.dropzone}>
                  <input {...getInputProps()} />
                  <div className={classes.infoContainer}>
                    <h2>Upload file</h2>
                    <p className={classes.info}>
                      Drag file here or <span>browse</span>
                    </p>
                    <p className={classes.supportedFiles}>
                      Supported file types: JPG, PNG, MP4, WEBM
                    </p>
                  </div>
                </div>
                <div className={classes.files}>
                  {files.map((file) => {
                    return (
                      <div className={classes.file} key={file.name}>
                        <span>{file.name}</span>
                        <button
                          type="button"
                          onClick={() => filterFiles(file.name)}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.16992 6.83001L6.82992 1.17001"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6.82992 6.83001L1.16992 1.17001"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    setCurrentStep(3);
                  }}
                  className={classes.button}
                >
                  Continue
                </button>
              </div>
            )}
            {currentStep === 3 && (
              <>
                <div className={classes.selectContainer}>
                  <Select
                    label="Type"
                    options={['Static', 'Video']}
                    name="type"
                    placeholder="Select the type"
                    value={formikThirdStep.values.type}
                    setFieldValue={formikThirdStep.setFieldValue}
                    setTouched={formikThirdStep.setTouched}
                    touched={formikThirdStep.touched}
                    type="none"
                    required
                  />
                  {formikThirdStep.errors.type &&
                    formikThirdStep.touched.type && (
                      <div className={classes.error}>
                        {formikThirdStep.errors.type}
                      </div>
                    )}
                </div>
                <div className={classes.selectContainer}>
                  <MultiSelect
                    label="Channels"
                    options={[
                      'Facebook',
                      'Instagram',
                      'YouTube',
                      'Snapchat',
                      'TikTok',
                    ]}
                    name="channels"
                    placeholder="Select the type"
                    value={formikThirdStep.values.channels}
                    setFieldValue={formikThirdStep.setFieldValue}
                    setTouched={formikThirdStep.setTouched}
                    touched={formikThirdStep.touched}
                    type="none"
                    required
                  />
                  {formikThirdStep.errors.channels &&
                    formikThirdStep.touched.channels && (
                      <div className={classes.error}>
                        {formikThirdStep.errors.channels}
                      </div>
                    )}
                </div>
                <div className={classes.selectContainer}>
                  <Select
                    label="Priority"
                    options={['P0', 'P1', 'P2', 'P3']}
                    name="priority"
                    placeholder="Please select brief priority"
                    value={formikThirdStep.values.priority}
                    setFieldValue={formikThirdStep.setFieldValue}
                    setTouched={formikThirdStep.setTouched}
                    touched={formikThirdStep.touched}
                    type="none"
                    required
                  />
                  {formikThirdStep.errors.priority &&
                    formikThirdStep.touched.priority && (
                      <div className={classes.error}>
                        {formikThirdStep.errors.priority}
                      </div>
                    )}
                </div>
                <button
                  type="button"
                  onClick={formikThirdStep.handleSubmit}
                  className={classes.button}
                >
                  Create
                </button>
              </>
            )}
          </form>
        </div>
      ) : (
        <div className={classes.success}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50 91.6666C72.9167 91.6666 91.6667 72.9166 91.6667 49.9999C91.6667 27.0833 72.9167 8.33325 50 8.33325C27.0834 8.33325 8.33337 27.0833 8.33337 49.9999C8.33337 72.9166 27.0834 91.6666 50 91.6666Z"
              fill="url(#paint0_linear_455_11214)"
              stroke="#EDEEFF"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.2916 49.9999L44.0833 61.7916L67.7083 38.2083"
              fill="url(#paint1_linear_455_11214)"
            />
            <path
              d="M32.2916 49.9999L44.0833 61.7916L67.7083 38.2083"
              stroke="#EDEEFF"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_455_11214"
                x1="8.33337"
                y1="49.9999"
                x2="91.6667"
                y2="49.9999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B224EF" />
                <stop offset="1" stopColor="#7579FF" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_455_11214"
                x1="32.2916"
                y1="49.9999"
                x2="67.7083"
                y2="49.9999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B224EF" />
                <stop offset="1" stopColor="#7579FF" />
              </linearGradient>
            </defs>
          </svg>
          <h1>Brief Added</h1>
          <Link className={classes.briefLink} to={`/tickets/${briefId}`}>
            View Brief
          </Link>
          <span className={classes.close} onClick={closeModal}>
            Close
          </span>
        </div>
      )}
    </Modal>
  );
}
