/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext } from 'react';

import axios from 'axios';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';

import TextInput from '../../components/AuthForm/TextInput';
import Select from '../../components/AuthForm/Select';
import classes from './styles.module.scss';
import { API_URL } from '../../constants/main';
import { UIContext } from '../../context';

const validationSchemaFirstStep = yup.object({
  name: yup.string().trim().required('This field is required'),
  lastName: yup.string().trim().required('This field is required'),
});

const validationSchemaSecondStep = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'The password should have at minimum length of 8'),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const validationSchemaThirdStep = yup.object({
  company: yup.string(),
  companyType: yup.string(),
  position: yup.string().required('This field is required'),
});

export default function SignUp() {
  const [signUpStep, setSignUpStep] = useState(1);

  const initialValuesFirstStep = {
    name: '',
    lastName: '',
  };

  const initialValuesSecondStep = {
    email: '',
    confirmPassword: '',
    password: '',
  };

  const initialValuesThirdStep = {
    company: '',
    companyType: '',
    position: '',
  };

  const navigate = useNavigate();

  const { notifyError } = useContext(UIContext);

  const formikFirstStep = useFormik({
    initialValues: initialValuesFirstStep,
    validationSchema: validationSchemaFirstStep,
    onSubmit: () => setSignUpStep(2),
  });

  const formikSecondStep = useFormik({
    initialValues: initialValuesSecondStep,
    validationSchema: validationSchemaSecondStep,
    onSubmit: () => setSignUpStep(3),
  });

  const onSubmit = async (values) => {
    try {
      await axios.post(`${API_URL}/users`, {
        ...formikFirstStep.values,
        ...formikSecondStep.values,
        ...values,
      });
      navigate('/login');
    } catch (error) {
      console.log(notifyError);
      notifyError('User already exists');
    }
  };

  const formikThirdStep = useFormik({
    initialValues: initialValuesThirdStep,
    validationSchema: validationSchemaThirdStep,
    onSubmit,
  });

  return (
    <div className={classes.SignUp}>
      <div className={classes.container}>
        <header className={classes.header}>
          <h1>Sign Up</h1>
          {signUpStep !== 1 && (
            <div className={classes.steps}>
              <span>Personal Info • </span>
              <span className={signUpStep === 2 && classes.active}>
                Sign In info
              </span>
              {signUpStep === 3 && (
                <span>
                  {' '}
                  •{' '}
                  <span className={signUpStep === 3 && classes.active}>
                    Company Info
                  </span>
                </span>
              )}
            </div>
          )}
        </header>
        <form onSubmit={formikFirstStep.handleSubmit}>
          {signUpStep === 1 && (
            <>
              <div className={classes.inputContainer}>
                <TextInput
                  type="name"
                  label="Name"
                  name="name"
                  placeholder="Write your name"
                  value={formikFirstStep.values.name}
                  onChange={formikFirstStep.handleChange}
                  onBlur={formikFirstStep.handleBlur}
                  required
                />
                {formikFirstStep.errors.name &&
                  formikFirstStep.touched.name && (
                    <div className={classes.error}>
                      {formikFirstStep.errors.name}
                    </div>
                  )}
              </div>
              <div className={classes.inputContainer}>
                <TextInput
                  type="name"
                  label="Last name"
                  name="lastName"
                  placeholder="Write your Last name"
                  value={formikFirstStep.values.lastName}
                  onChange={formikFirstStep.handleChange}
                  onBlur={formikFirstStep.handleBlur}
                  required
                />
                {formikFirstStep.errors.lastName &&
                  formikFirstStep.touched.lastName && (
                    <div className={classes.error}>
                      {formikFirstStep.errors.lastName}
                    </div>
                  )}
              </div>
            </>
          )}
          {signUpStep === 2 && (
            <>
              <div className={classes.inputContainer}>
                <TextInput
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Write your Email address"
                  value={formikSecondStep.values.email}
                  onChange={formikSecondStep.handleChange}
                  onBlur={formikSecondStep.handleBlur}
                  required
                />
                {formikSecondStep.errors.email &&
                  formikSecondStep.touched.email && (
                    <div className={classes.error}>
                      {formikSecondStep.errors.email}
                    </div>
                  )}
              </div>
              <div className={classes.inputContainer}>
                <TextInput
                  label="Password"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formikSecondStep.values.password}
                  onChange={formikSecondStep.handleChange}
                  onBlur={formikSecondStep.handleBlur}
                  required
                />
                {formikSecondStep.errors.password &&
                  formikSecondStep.touched.password && (
                    <div className={classes.error}>
                      {formikSecondStep.errors.password}
                    </div>
                  )}
              </div>
              <div className={classes.inputContainer}>
                <TextInput
                  label="Confirm the password"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  value={formikSecondStep.values.confirmPassword}
                  onChange={formikSecondStep.handleChange}
                  onBlur={formikSecondStep.handleBlur}
                  required
                />
                {formikSecondStep.errors.confirmPassword &&
                  formikSecondStep.touched.confirmPassword && (
                    <div className={classes.error}>
                      {formikSecondStep.errors.confirmPassword}
                    </div>
                  )}
              </div>
            </>
          )}
          {signUpStep === 3 && (
            <>
              <div className={classes.inputContainer}>
                <TextInput
                  type="company"
                  label="Company"
                  name="company"
                  placeholder="Enter your Company"
                  value={formikThirdStep.values.company}
                  onChange={formikThirdStep.handleChange}
                  onBlur={formikThirdStep.handleBlur}
                  required
                />
                {formikThirdStep.errors.company &&
                  formikThirdStep.touched.company && (
                    <div className={classes.error}>
                      {formikThirdStep.errors.company}
                    </div>
                  )}
              </div>
              <div className={classes.selectContainer}>
                <Select
                  label="Type"
                  options={['Creativity Agency', 'Brand']}
                  name="companyType"
                  value={formikThirdStep.values.companyType}
                  setFieldValue={formikThirdStep.setFieldValue}
                  setTouched={formikThirdStep.setTouched}
                  touched={formikThirdStep.touched}
                  placeholder="Select the company type"
                  required
                />
                {formikThirdStep.errors.companyType &&
                  formikThirdStep.touched.companyType && (
                    <div className={classes.error}>
                      {formikThirdStep.errors.companyType}
                    </div>
                  )}
              </div>
              <div className={classes.selectContainer}>
                <Select
                  label="Position"
                  options={['Founder', 'Marketer', 'Designer', 'Legal']}
                  name="position"
                  value={formikThirdStep.values.position}
                  setFieldValue={formikThirdStep.setFieldValue}
                  setTouched={formikThirdStep.setTouched}
                  touched={formikThirdStep.touched}
                  required
                  placeholder="Select your position"
                />
                {formikThirdStep.errors.position &&
                  formikThirdStep.touched.position && (
                    <div className={classes.error}>
                      {formikThirdStep.errors.position}
                    </div>
                  )}
              </div>
            </>
          )}
          <button
            className={classes.submitButton}
            type="button"
            onClick={() => {
              if (signUpStep === 1) {
                formikFirstStep.handleSubmit();
              } else if (signUpStep === 2) {
                formikSecondStep.handleSubmit();
              } else if (signUpStep === 3) {
                formikThirdStep.handleSubmit();
              }
            }}
          >
            {signUpStep === 3 ? 'Create Account' : 'Continue'}
          </button>
          <span className={classes.link}>
            Have an account? <Link to="/signin">Sign In</Link>
          </span>
        </form>
      </div>
    </div>
  );
}
