import React, { useEffect, useState } from 'react';

import axios from 'axios';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

import classes from './styles.module.scss';
import { API_URL } from '../../../constants/main';

export default function Notifications({ isCollapsed, setIsCollapsed }) {
  const [notifications, setNotifications] = useState([]);
  const [height, setHeight] = useState('');
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = 'unset';
    };
  }, []);

  useEffect(() => {
    const changeHeight = () => {
      setHeight(document.body.offsetHeight);
    };

    window.addEventListener('resize', changeHeight);

    setTimeout(() => {
      changeHeight();
    }, 300);

    return () => {
      window.removeEventListener('resize', changeHeight);
    };
  }, []);

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const response = await axios.get(`${API_URL}/notifications`);
        setNotifications(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getNotifications();
  }, []);

  useEffect(() => {
    setIsAnimationRunning(true);
    setTimeout(() => {
      setIsAnimationRunning(false);
    }, 200);
  }, [isCollapsed]);

  return (
    <div
      style={{ height }}
      className={classNames(
        classes.RightSidebar,
        isCollapsed && classes.collapsed
      )}
    >
      <button
        type="button"
        className={classes.hideButton}
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        <svg
          width="12"
          height="18"
          viewBox="0 0 12 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.37504 1.64583L9.70837 8.99999L2.37504 16.3542"
            stroke="#EDEEFF"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <header>
        <button type="button">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.17 20.475V27.885H4.82996V20.475C4.82996 13.245 10.68 7.39499 17.91 7.39499H18.09C25.32 7.39499 31.17 13.245 31.17 20.475Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 3V7.39499"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.475 27.885C23.28 30.75 20.895 33 18 33C15.105 33 12.72 30.75 12.525 27.885H23.475Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </header>
      <div className={classes.users}>
        {notifications.map((notification) => {
          return (
            <div key={notification.id} className={classes.user}>
              {/* <div className={classes.avatar} /> */}
              <div className={classes.userData}>
                <div
                  style={{ display: isAnimationRunning ? 'none' : 'block' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(notification.text),
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
