/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useContext } from 'react';

import Tag from '../Tag';
import classes from './styles.module.scss';
import useResizeObserver from '../../../hooks/useResizeObserver';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { TicketContext /* UIContext */ } from '../../../context';

// const timer = '';

export default function Image({
  src,
  alt,
  mediaViewRef,
  fileId,
  width,
  setWidth,
  height,
  setHeight,
  imageRef,
  startingPoint,
  setStartingPoint,
  editorRef,
  commentsMode,
  editorContainerRef,
  tags,
}) {
  const [isSelecting, setIsSelecting] = useState(false);
  // const [resizeScalingFactor, setResizeScalingFactor] = useState(1);
  const [coords, setCoords] = useState({});
  const [imageContainerMaxHeight, setImageContainerMaxHeight] = useState('');
  const [imageCoords, setImageCoords] = useState({});
  const [imageComponentCoords, setImageComponentCoords] = useState({});

  const prevImageSize = useRef();
  const selectionContainerRef = useRef();
  const selectionRef = useRef();

  const imageComponentRef = useRef();
  // const imageWidth = useResizeObserver(imageRef).width;
  const mediaViewWidth = useResizeObserver(mediaViewRef).width;

  const { activeImageTag, setActiveImageTag } = useContext(TicketContext);

  useOnClickOutside(
    selectionRef,
    () => {
      setActiveImageTag(null);
      setWidth(0);
    },
    editorContainerRef
  );

  useEffect(() => {
    if (!activeImageTag) {
      setWidth(0);
    }
  }, [activeImageTag, setWidth]);

  useEffect(() => {
    if (activeImageTag) {
      setStartingPoint({
        top: imageRef.current.clientHeight * (activeImageTag.top / 100),
        left: imageRef.current.clientWidth * (activeImageTag.left / 100),
        topPercentage: activeImageTag.top,
        leftPercentage: activeImageTag.left,
      });
      setWidth(imageRef.current.clientWidth * (activeImageTag.width / 100));
      setHeight(imageRef.current.clientHeight * (activeImageTag.height / 100));
    }
  }, [activeImageTag, imageRef, setHeight, setStartingPoint, setWidth]);

  useEffect(() => {
    const imgCoords = imageRef.current.getBoundingClientRect();
    const imgComponentCoords =
      imageComponentRef.current.getBoundingClientRect();
    setImageCoords({
      top: imgCoords.top,
      left: imgCoords.left,
      width: imgCoords.width,
      height: imgCoords.height,
    });
    setImageComponentCoords({
      top: imgComponentCoords.top,
      left: imgComponentCoords.left,
    });
  }, [mediaViewWidth, imageContainerMaxHeight, imageRef]);

  const onMouseDown = (event) => {
    if (commentsMode === 'feedback') {
      return;
    }

    event.preventDefault();

    setWidth(0);
    setHeight(0);
    prevImageSize.current = imageRef.current.clientWidth;
    // setResizeScalingFactor(1);

    const elemCoords = imageRef.current.getBoundingClientRect();
    setCoords(elemCoords);

    setStartingPoint({
      top: event.clientY - elemCoords.top,
      left: event.clientX - elemCoords.left,
      topPercentage:
        (100 / imageRef.current.clientHeight) *
        (event.clientY - elemCoords.top),
      leftPercentage:
        (100 / imageRef.current.clientWidth) *
        (event.clientX - elemCoords.left),
    });
    setIsSelecting(true);
  };

  const onMouseUp = () => {
    setIsSelecting(false);
  };

  const onMouseMove = (event) => {
    if (!isSelecting) {
      return;
    }
    event.preventDefault();
    setWidth(event.clientX - coords.left - startingPoint.left);
    setHeight(event.clientY - coords.top - startingPoint.top);
  };

  useEffect(() => {
    setWidth(0);
    setHeight(0);
    document.body.addEventListener('mouseup', onMouseUp);
    return () => {
      document.body.removeEventListener('mouseup', onMouseUp);
    };
  }, [setHeight, setWidth, src]);

  useEffect(() => {
    if (!isSelecting) {
      editorRef?.current?.focusEditor();
    }
  }, [editorRef, isSelecting]);

  /* useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (imageWidth !== prevImageSize.current) {
        setResizeScalingFactor(
          (imageWidth / (prevImageSize.current || imageWidth)).toFixed(2)
        );
      }
    }, 10);
  }, [imageWidth]); */

  useEffect(() => {
    setWidth(0);
    setActiveImageTag(null);
  }, [fileId, commentsMode, setWidth, setActiveImageTag]);

  const top = height >= 0 ? `${startingPoint.topPercentage}%` : '';

  const left = width >= 0 ? `${startingPoint.leftPercentage}%` : '';

  return (
    <div
      ref={imageComponentRef}
      className={classes.Image}
      style={{
        maxHeight: imageContainerMaxHeight,
      }}
    >
      <div
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        className={classes.selectionContainer}
        ref={selectionContainerRef}
        style={{
          // pointerEvents: !isSelecting && width ? 'auto' : 'none',
          left: imageCoords.left - imageComponentCoords.left,
          top: imageCoords.top - imageComponentCoords.top,
          width: imageCoords.width,
          height: imageCoords.height,
        }}
      >
        {width !== 0 && (
          <div
            ref={selectionRef}
            className={classes.selection}
            style={{
              // width: Math.abs(width) * resizeScalingFactor,
              // height: Math.abs(height) * resizeScalingFactor,
              width: Math.abs(width),
              height: Math.abs(height),
              left,
              right:
                width < 0
                  ? imageRef.current.clientWidth - startingPoint.left
                  : '',
              top,
              bottom:
                height < 0
                  ? imageRef.current.clientHeight - startingPoint.top
                  : '',
            }}
          />
        )}
        {commentsMode === 'tags' &&
          tags &&
          tags.map((tag) => {
            return (
              <Tag
                tag={tag}
                key={tag.id}
                top={tag.top}
                left={tag.left + tag.width / 2}
              />
            );
          })}
      </div>
      <img
        onLoad={() => {
          prevImageSize.current = imageRef.current.clientWidth;
          setImageContainerMaxHeight(imageRef.current.naturalHeight);
        }}
        ref={imageRef}
        src={src}
        alt={alt}
        className={classes.image}
      />
    </div>
  );
}
