import { useEffect, useState, useCallback } from 'react';

export default function useDraggable(
  draggableRef,
  setIsCursorAnimationDisabled
) {
  const [yClickPosition, setYClickPosition] = useState(0);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const move = useCallback(
    (event) => {
      if (setIsCursorAnimationDisabled) {
        setIsCursorAnimationDisabled(true);
      }
      setXPosition(event.clientX);
      setYPosition(event.clientY);
    },
    [setIsCursorAnimationDisabled]
  );

  const onMouseUp = useCallback(() => {
    if (setIsCursorAnimationDisabled) {
      setIsCursorAnimationDisabled(false);
    }
    setIsDragging(false);
    document.body.removeEventListener('mousemove', move);
    document.body.removeEventListener('mouseup', onMouseUp);
  }, [move, setIsCursorAnimationDisabled]);

  const onMouseDown = useCallback(
    (event) => {
      const draggableCoords = draggableRef.current.getBoundingClientRect();
      setYClickPosition(event.clientY - draggableCoords.top);
      event.preventDefault();
      setIsDragging(true);
      document.body.addEventListener('mousemove', move);
      document.body.addEventListener('mouseup', onMouseUp);
    },
    [draggableRef, move, onMouseUp]
  );

  useEffect(() => {
    const draggableRefCurrent = draggableRef.current;

    if (draggableRefCurrent) {
      draggableRefCurrent.addEventListener('mousedown', onMouseDown);
    }
    return () => {
      if (draggableRefCurrent) {
        document.body.removeEventListener('mousemove', move);
        document.body.removeEventListener('mouseup', onMouseUp);
        draggableRefCurrent.removeEventListener('mousedown', onMouseDown);
      }
    };
  }, [draggableRef, move, onMouseDown, onMouseUp]);

  return {
    xPosition,
    yPosition,
    yClickPosition,
    isDragging,
  };
}
