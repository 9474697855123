/* eslint-disable react/no-danger */
import React, { useState, useRef, useContext } from 'react';

import axios from 'axios';
import TimeAgo from 'javascript-time-ago';
import classNames from 'classnames';
import ReplyBox from '../ReplyBox';
import classes from './styles.module.scss';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import videoTimeToHMS from '../../../../helpers/videoTimeToHMS';
import { API_URL } from '../../../../constants/main';
import { TicketContext, UIContext } from '../../../../context';

export default function Comment({
  commentData,
  isReply,
  getComments,
  refetchTags,
  comments,
  nested,
  firstOne,
  user,
}) {
  const [isReplyBoxVisible, setIsReplyBoxVisible] = useState(false);
  const [areRepliesVisible, setAreRepliesVisible] = useState(false);

  const timeAgo = new TimeAgo('en-US');

  const replyBoxContainerRef = useRef();
  const replyButtonRef = useRef();
  const commentText = useRef();

  const { setActiveVideoTag, setActiveImageTag } = useContext(TicketContext);
  const { activeWorkspace } = useContext(UIContext);

  useOnClickOutside(
    replyBoxContainerRef,
    () => {
      setIsReplyBoxVisible(false);
    },
    replyButtonRef
  );

  const createReply = async () => {
    try {
      await axios.post(`${API_URL}/comments`, {
        ticketId: commentData.ticketId,
        commentId: commentData.id,
        textContent: commentText.current,
        workspaceId: activeWorkspace.id,
      });
      setIsReplyBoxVisible(false);
      getComments();
      refetchTags();
      setAreRepliesVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleLike = async () => {
    try {
      await axios.post(`${API_URL}/comments/${commentData.id}/like`);
      getComments();
      refetchTags();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={classNames(
        classes.Comment,
        commentData.top && classes.imageTag,
        nested && classes.nested,
        areRepliesVisible && classes.repliesVisible,
        firstOne && classes.firstOne
      )}
    >
      <header className={classes.commentHeader}>
        <div className={classes.userBlock}>
          <div className={classes.userPic}>
            {commentData?.User?.name?.[0] ||
              comments.find((comment) => comment.id === commentData.id).User
                .name[0]}
          </div>
          <h1>
            {commentData?.User?.name ||
              comments.find((comment) => comment.id === commentData.id).User
                .name}
          </h1>
        </div>
        {commentData?.createdAt && (
          <div className={classes.createdAt}>
            {timeAgo.format(new Date(commentData.createdAt))}
          </div>
        )}
      </header>
      <div
        className={classes.textContent}
        onClick={() => {
          if (commentData.top) {
            setActiveImageTag(commentData);
          }
        }}
      >
        {commentData.startPosition !== null && (
          <div
            className={classes.timeMark}
            onClick={() => {
              setActiveVideoTag(commentData);
            }}
          >
            <span>{videoTimeToHMS(commentData.startPosition)}</span>{' '}
            {commentData.endPosition !== null &&
              commentData.startPosition !== commentData.endPosition && (
                <span>- {videoTimeToHMS(commentData.endPosition)}</span>
              )}
          </div>
        )}
        <div dangerouslySetInnerHTML={{ __html: commentData.textContent }} />
      </div>
      <footer className={classes.footer}>
        <div className={classes.likes}>
          {!commentData.Likes.some((like) => like.userId === user?.id) ? (
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleLike}
            >
              <path
                d="M6.52176 13.7625L8.83875 15.5625C9.13771 15.8625 9.81039 16.0125 10.2588 16.0125H13.099C13.9959 16.0125 14.9676 15.3375 15.1918 14.4375L16.9856 8.96248C17.3593 7.91248 16.6866 7.01248 15.5655 7.01248H12.5758C12.1274 7.01248 11.7537 6.63748 11.8284 6.11248L12.2021 3.71248C12.3516 3.03748 11.9031 2.28748 11.2305 2.06248C10.6325 1.83748 9.88513 2.13748 9.58616 2.58748L6.52176 7.16248"
                stroke="#5F6073"
                strokeMiterlimit="10"
              />
              <path
                d="M2.70993 13.7626V6.41255C2.70993 5.36255 3.15838 4.98755 4.20476 4.98755H4.95218C5.99856 4.98755 6.44701 5.36255 6.44701 6.41255V13.7626C6.44701 14.8126 5.99856 15.1875 4.95218 15.1875H4.20476C3.15838 15.1875 2.70993 14.8126 2.70993 13.7626Z"
                stroke="#5F6073"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={toggleLike}
            >
              <path
                d="M6.52176 13.7625L8.83875 15.5625C9.13771 15.8625 9.81039 16.0125 10.2588 16.0125H13.099C13.9959 16.0125 14.9676 15.3375 15.1918 14.4375L16.9856 8.96248C17.3593 7.91248 16.6866 7.01248 15.5655 7.01248H12.5758C12.1274 7.01248 11.7537 6.63748 11.8284 6.11248L12.2021 3.71248C12.3516 3.03748 11.9031 2.28748 11.2305 2.06248C10.6325 1.83748 9.88513 2.13748 9.58616 2.58748L6.52176 7.16248"
                fill="#7579FF"
              />
              <path
                d="M2.70993 13.7626V6.41255C2.70993 5.36255 3.15838 4.98755 4.20476 4.98755H4.95218C5.99856 4.98755 6.44701 5.36255 6.44701 6.41255V13.7626C6.44701 14.8126 5.99856 15.1875 4.95218 15.1875H4.20476C3.15838 15.1875 2.70993 14.8126 2.70993 13.7626Z"
                fill="#7579FF"
              />
            </svg>
          )}
          <span>{commentData.Likes?.length}</span>
        </div>
        {!isReply && (
          <div
            className={classes.replies}
            onClick={() => {
              if (!commentData?.Reply?.length) {
                return;
              }
              setAreRepliesVisible((prevState) => !prevState);
            }}
          >
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6133 14.1451H14.0267C13.4093 14.1451 12.8227 14.3775 12.3905 14.7975L11.0707 16.0651C10.4687 16.6426 9.48854 16.6426 8.88653 16.0651L7.56674 14.7975C7.13453 14.3775 6.54024 14.1451 5.93052 14.1451H5.35167C4.07047 14.1451 3.03625 13.1476 3.03625 11.9176V3.73505C3.03625 2.50505 4.07047 1.50757 5.35167 1.50757H14.6133C15.8945 1.50757 16.9287 2.50505 16.9287 3.73505V11.9176C16.9287 13.1401 15.8945 14.1451 14.6133 14.1451Z"
                stroke="#5F6073"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>{commentData.Reply?.length || 0}</span>
          </div>
        )}
        {!isReply && (
          <button
            type="button"
            className={classes.replyButton}
            ref={replyButtonRef}
            onClick={() => {
              setIsReplyBoxVisible((prevState) => !prevState);
            }}
          >
            Reply
          </button>
        )}
      </footer>
      {isReplyBoxVisible && (
        <div className={classes.replyBoxContainer} ref={replyBoxContainerRef}>
          <ReplyBox commentText={commentText} onSubmit={createReply} isNested />
        </div>
      )}
      {areRepliesVisible &&
        commentData.Reply?.map((comment, index) => {
          return (
            <Comment
              firstOne={index === 0}
              nested
              commentData={comment}
              key={comment.id}
              isReply
              getComments={getComments}
              refetchTags={refetchTags}
              comments={comments}
              user={user}
            />
          );
        })}
    </div>
  );
}
