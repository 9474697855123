import React, { useRef, useEffect } from 'react';

import Multiselect from 'multiselect-react-dropdown';

import classNames from 'classnames';
import classes from './styles.module.scss';
import './style.scss';

export default function MultiSelect({
  options,
  value,
  setFieldValue,
  label,
  width,
  placeholder,
  name,
}) {
  const multiselectContainerRef = useRef();

  const handleChange = (selectedItems) => {
    const selectValue = selectedItems.map((item) => item.name).join(',');
    setFieldValue(name, selectValue);
  };

  /* useEffect(() => {
    const multiselectInput = document.querySelector('#search_input');
    const multiselectWrapper = document.querySelector(
      '.search-wrapper.searchWrapper'
    );
    multiselectInput.name = name;

    const onFocus = () => {
      multiselectWrapper.style.boxShadow = '0 0 0 .25rem rgba(13,110,253,.25)';
    };

    const onFocusOut = () => {
      multiselectWrapper.style.boxShadow = 'none';
    };

    multiselectInput.addEventListener('focus', onFocus);
    multiselectInput.addEventListener('focusout', onFocusOut);

    return () => {
      multiselectInput.removeEventListener('focus', onFocus);
      multiselectInput.removeEventListener('focusout', onFocusOut);
    };
  }, []); */

  useEffect(() => {
    const input = document.body.querySelector('#search_input');
    input.style.cursor = 'default';
    input.readOnly = true;
  }, []);

  useEffect(() => {
    if (multiselectContainerRef.current) {
      multiselectContainerRef.current.style.setProperty(
        '--width',
        `${multiselectContainerRef.current.clientWidth}px`
      );
    }
  }, [multiselectContainerRef]);

  return (
    <div className={classNames(classes.MultiSelect)} data-width={`${width}px`}>
      <span
        style={{
          width: width || '',
        }}
        className={classes.label}
      >
        {label}
      </span>
      <div
        className={classes.multiselectContainer}
        ref={multiselectContainerRef}
      >
        <Multiselect
          hidePlaceholder
          style={{
            chips: {
              whiteSpace: 'normal',
              fontFamily: 'Poppins',
              fontSize: 12,
            },
            multiselectContainer: {
              maxWidth: width,
            },
            searchBox: {
              backgroundColor: '#EDEEFF',
              paddingTop: 8,
              paddingLeft: 30,
              paddingRight: 30,
              minHeight: 40,
              borderRadius: 4,
              border: 'none',
            },
          }}
          options={options} // Options to display in the dropdown
          selectedValues={
            value &&
            value !== '' &&
            value.split(',').map((item) => {
              return { name: item };
            })
          }
          onSelect={handleChange}
          onRemove={handleChange}
          displayValue="name"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
