/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useRef } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

// import UploadMenu from './UploadMenu';
import DownloadButton from './DownloadButton';
import CreateBriefModal from '../../../modals/CreateBriefModal';
import WorkspaceMenu from './WorkspaceMenu';
import useOnClikOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import { UIContext } from '../../../context';
import UserService from '../../../services/UserService';

export default function Header({ headerBackButton }) {
  const [isAddEntityMenuVisible, setIsAddEntityMenuVisible] = useState(false);
  const [isWorkspaceMenuVisible, setIsWorkspaceMenuVisible] = useState(false);
  const [isCreateBriefModalVisible, setIsCreateBriefModalVisible] =
    useState(false);

  const { data: user } = useQuery('me', UserService.getMe);

  const navigate = useNavigate();
  const {
    searchQuery,
    setSearchQuery,
    setIsSidebarHidden,
    workspaces,
    viewMode,
  } = useContext(UIContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const addEntityMenuRef = useRef();
  const addButtonRef = useRef();
  const workspaceMenuContainerRef = useRef();
  const workspaceButtonRef = useRef();

  useOnClikOutside(
    addEntityMenuRef,
    () => setIsAddEntityMenuVisible(false),
    addButtonRef
  );

  useOnClikOutside(
    workspaceMenuContainerRef,
    () => setIsWorkspaceMenuVisible(false),
    workspaceButtonRef
  );

  return (
    <div className={classes.Header}>
      {isTabletOrMobile && (
        <div
          className={classes.burger}
          onClick={() => setIsSidebarHidden(false)}
        />
      )}
      <div className={classes.userBlock}>
        <p className={classes.username}>Hi, {user?.name}. 🎉</p>
        <p className={classes.date}>
          Today is {moment().format('dddd')} {moment().format('Do')}
        </p>
      </div>
      <div className={classes.searchbarContainer}>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </div>
      <div className={classes.buttons}>
        {viewMode === 'grid' && <DownloadButton />}
        {/* <UploadMenu /> */}
        {headerBackButton ? (
          <button
            type="button"
            className={classes.backButton}
            onClick={() => navigate(-1)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.17163 9.17163L14.8285 14.8285"
                stroke="#7579FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.17151 14.8285L14.8284 9.17163"
                stroke="#7579FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="#7579FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        ) : (
          <>
            <button
              type="button"
              className={classes.workspaceButton}
              onClick={() =>
                setIsWorkspaceMenuVisible((prevState) => !prevState)
              }
              ref={workspaceButtonRef}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.77 11.25H15.73C13.72 11.25 12.75 10.36 12.75 8.52V3.98C12.75 2.14 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.14 22.75 3.98V8.51C22.75 10.36 21.77 11.25 19.77 11.25ZM15.73 2.75C14.39 2.75 14.25 3.13 14.25 3.98V8.51C14.25 9.37 14.39 9.74 15.73 9.74H19.77C21.11 9.74 21.25 9.36 21.25 8.51V3.98C21.25 3.12 21.11 2.75 19.77 2.75H15.73Z"
                  fill="white"
                />
                <path
                  d="M19.77 22.75H15.73C13.72 22.75 12.75 21.77 12.75 19.77V15.73C12.75 13.72 13.73 12.75 15.73 12.75H19.77C21.78 12.75 22.75 13.73 22.75 15.73V19.77C22.75 21.77 21.77 22.75 19.77 22.75ZM15.73 14.25C14.55 14.25 14.25 14.55 14.25 15.73V19.77C14.25 20.95 14.55 21.25 15.73 21.25H19.77C20.95 21.25 21.25 20.95 21.25 19.77V15.73C21.25 14.55 20.95 14.25 19.77 14.25H15.73Z"
                  fill="white"
                />
                <path
                  d="M8.27 11.25H4.23C2.22 11.25 1.25 10.36 1.25 8.52V3.98C1.25 2.14 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.14 11.25 3.98V8.51C11.25 10.36 10.27 11.25 8.27 11.25ZM4.23 2.75C2.89 2.75 2.75 3.13 2.75 3.98V8.51C2.75 9.37 2.89 9.74 4.23 9.74H8.27C9.61 9.74 9.75 9.36 9.75 8.51V3.98C9.75 3.12 9.61 2.75 8.27 2.75H4.23Z"
                  fill="white"
                />
                <path
                  d="M8.27 22.75H4.23C2.22 22.75 1.25 21.77 1.25 19.77V15.73C1.25 13.72 2.23 12.75 4.23 12.75H8.27C10.28 12.75 11.25 13.73 11.25 15.73V19.77C11.25 21.77 10.27 22.75 8.27 22.75ZM4.23 14.25C3.05 14.25 2.75 14.55 2.75 15.73V19.77C2.75 20.95 3.05 21.25 4.23 21.25H8.27C9.45 21.25 9.75 20.95 9.75 19.77V15.73C9.75 14.55 9.45 14.25 8.27 14.25H4.23Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              ref={addButtonRef}
              type="button"
              className={classes.addButton}
              onClick={() =>
                setIsAddEntityMenuVisible((prevState) => !prevState)
              }
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 12H16"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 16V8"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </div>
      {isAddEntityMenuVisible && (
        <div className={classes.addEntityMenu} ref={addEntityMenuRef}>
          <svg
            width="21"
            height="24"
            viewBox="0 0 21 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.74518 2.38894C8.78702 -0.0272701 12.213 -0.0272703 13.2548 2.38894L20.3856 18.9266C21.2395 20.9069 19.7874 23.1144 17.6308 23.1144H3.36921C1.21262 23.1144 -0.239502 20.9069 0.614393 18.9266L7.74518 2.38894Z"
              fill="white"
            />
          </svg>
          <ul>
            <li
              className={classes.briefs}
              onClick={() => {
                setIsAddEntityMenuVisible(false);
                setIsCreateBriefModalVisible(true);
              }}
            >
              Briefs
            </li>
            <li
              className={classes.workspaces}
              onClick={() => {
                setIsAddEntityMenuVisible(false);
                navigate('/dashboard/add-workspace');
              }}
            >
              Workspaces
            </li>
          </ul>
        </div>
      )}
      {isWorkspaceMenuVisible && (
        <div
          className={classes.workspaceMenuContainer}
          ref={workspaceMenuContainerRef}
        >
          <WorkspaceMenu workspaces={workspaces} />
        </div>
      )}
      <CreateBriefModal
        show={isCreateBriefModalVisible}
        handleClose={() => setIsCreateBriefModalVisible(false)}
      />
    </div>
  );
}
