import axios from 'axios';

import { API_URL } from '../constants/main';

const TicketsService = {
  async getTickets({ type, status, searchTerm, channel, activeWorkspace }) {
    const response = await axios.get(`${API_URL}/tickets`, {
      params: {
        type,
        searchQuery: searchTerm,
        channel,
        status,
        workspaceId: activeWorkspace.id,
      },
    });

    return response.data;
  },
  async getAllTickets({ activeWorkspace }) {
    const response = await axios.get(`${API_URL}/tickets/alltickets`, {
      params: {
        workspaceId: activeWorkspace.id,
      },
    });
    return response.data;
  },
};

export default TicketsService;
